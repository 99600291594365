import axios, {isCancel, AxiosError} from 'axios';
const baseURL = process.env.REACT_APP_BASEURL;

export async function postForm(value) {
    try {
        let headers = {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + keycloak.default.token,
        };
        console.log({baseURL});
        return await axios.post(`${baseURL}wufoo/form`, value);
    } catch (error) {
        console.log({error});
    }
}

export async function UpdateForm(id,data) {
    try {
        let headers = {
          "Content-Type": "application/json",
        //   Authorization: "Bearer " + keycloak.default.token,
        };
        console.log({data});
        return axios.put(`${baseURL}wufoo/updatewufoo/${id}`, data, {
          headers: headers,
        });
    } catch (error) {
        console.log({error});
    }
}

export async function getForm(value) {
    try {
        let headers = {
          "Content-Type": "application/json",
        };
      
        return axios.get(`${baseURL}wufoo/get-form`, {
          headers: headers,
          params: {
            id:value
          },
        });
    } catch (error) {
        console.log({error});
    }
}

export async function getSelectedForm(value) {
    try {
        let headers = {
          "Content-Type": "application/json",
        };
      
        return axios.get(`${baseURL}wufoo/get-selected-form`, {
          headers: headers,
          params: {
            id:value
          },
        });
    } catch (error) {
        console.log({error});
    }
}
export async function getWufooRecurringCustomer(value) {
    try {
        let headers = {
          "Content-Type": "application/json",
        };
      
        return axios.get(`${baseURL}wufoo/recurring/customer/data`, {
          headers: headers,
          params: {
            search:value
          },
        });
    } catch (error) {
        console.log({error});
    }
}

export async function SaveEnteredEmail(value) {
    try {
        let headers = {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + keycloak.default.token,
        };
        console.log({baseURL});
        return await axios.post(`${baseURL}tfg/popup/email`, value);
    } catch (error) {
        console.log({error});
    }
}