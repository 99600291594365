import React, { useContext, useEffect, useState } from "react";
import TheFunnelguruRouter from './routers';
import { createContext } from "react";
const App=()=> {
  return (
    <>
      <div>
        <TheFunnelguruRouter/>
      </div>
    </>
  );
}

export default App;
