import React, { useState } from "react";
import * as serviceCaller from "../services/form";
import styled from 'styled-components';
import {
    Form,
    Button,
    Input,
} from 'antd';

const Wrapper = styled.div`
    width:100%;
    .ant-form-item {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
        margin-bottom: 10px;
        vertical-align: top;
    }
    .ant-input {
        background-color: #EBF0F2;
        border-color: #D6DEE3;
        border-width: 2px;
        border-style: solid;
        color: #444444;
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        font-family: Helvetica,sans-serif;
        border-bottom: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0px;
        box-sizing: border-box !important;
        margin-top: 0.25rem !important;
        padding: 8px 12px!important;
    }
    .ant-form-item .ant-form-item-label >label {
        width: auto;
        color: #000000;
        text-decoration: none;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        font-family: Helvetica, sans-serif;
    }
    .ant-form-vertical .ant-form-item-label, :where(.css-dev-only-do-not-override-k83k30).ant-col-24.ant-form-item-label, :where(.css-dev-only-do-not-override-k83k30).ant-col-xl-24.ant-form-item-label {
        margin: 0;
        padding: 0;
        white-space: initial;
        text-align: start;
    }
`;
const CustomerForm = () => {
    const [form] = Form.useForm();
    const [EmailValidation, setEmailValidation] = useState(undefined);
    const [ButtonLoading, setButtonLoading] = useState(false);

    return (
        <Wrapper>
            <Form
                form={form}
                layout="vertical"
                onFinish={async(value)=>{
                    setButtonLoading(true);
                    setEmailValidation(undefined);
                    if(value.email){
                        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.email))
                        {
                            let SaveForm = await serviceCaller.SaveEnteredEmail({
                                email:value.email,
                            });
                            if(SaveForm.status===200){
                                setButtonLoading(false);
                                console.log({SaveForm});
                                window.top.location.href = "https://thefunnelguru.com/restrictedcategory"; 
                            }else{
                                setButtonLoading(false);
                                window.top.location.href = "https://thefunnelguru.com/restrictedcategory"; 
                            }
                        }else{
                            setButtonLoading(false);
                            setEmailValidation("*Please Enter Valid Email-id");
                            console.log("value Email - if : false");
                        }
                    }else{
                        setButtonLoading(false);
                        setEmailValidation("*Email-id is required");
                    }
                    console.log({value});
                }}
                requiredMark={false}
            >
                <Form.Item
                    name="email"
                    label="Email"
                >
                    <Input 
                        placeholder="Email"
                        onChange={()=>{
                            setEmailValidation(undefined);
                        }}
                    />
                </Form.Item>
                {EmailValidation && (
                    <p style={{fontSize:"10px",color:"red",fontWeight:"bold"}}>{EmailValidation}</p>
                )}
                <Form.Item>
                    <Button 
                        loading={ButtonLoading}
                        type="primary" 
                        htmlType="submit" 
                        style={{
                            background:"none",
                            backgroundColor:"#333333",
                            width:"100%",
                            borderRadius:"0px",
                            boxShadow:"none"
                        }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Wrapper>
    )
}

export default CustomerForm;