import styled from "styled-components";
import axios, { isCancel, AxiosError } from 'axios';
import SuccessPaymentImage from "../images/success_payment.png";
import AMZ_ARBITRAGE_LOGO from "../images/AMZ-ARBITRAGE-LOGO.png";
import Logo from "../images/TFG-Logo.png";
import Congratulation from "../images/success_congratulation.gif";
import Warning from "../images/warning.png";
import Loading from "../images/load.png";
import React, { useEffect, useState } from "react";
import {
  message
} from "antd";

const Wrapper = styled.div`
  margin: 0%;
  .tfg-payment-success-container {
    height:${window.screen.height > 1000 ? "calc(100vh - 19px)" : "calc(100vh - 0px)"};
    overflow: scroll;
    overflow-x: hidden;
  }
  .logoIMG {
    width: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "150px !important" : "14% !important"};
  }
  .header-title {
    width: 100%;
    position: absolute;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid rgb(0 0 255 / 20%);
  }
  .tfg-container-box {
    margin: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "80px 0 70px 0" : "100px 0 100px 0"};
  }
  .tfg-success-image-container {
    text-align:center;
    margin: 30px 0 0px 0;
  }
  .payment-success-image {
    width: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "35%" : "14%"};
  }
  .tfg-success-message-container {
    font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
    font-weight: 700;
    font-size: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "20px" : "52px"};
    text-align: center;
    padding: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "15px 0 0px 0;" : "30px 0 0px 0"};
  }
  .tfg-success-notes-container {
    font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
    font-weight: 700;
    font-size:  ${(window?.navigator?.userAgent?.includes("Mobile")) ? "12px" : "20px"};
    text-align: -webkit-center;
    padding: 10px 0 0px 0;  
    text-align: center;
  }
  .partner-container {
    text-align: -webkit-center;
    margin:  ${(window?.navigator?.userAgent?.includes("Mobile")) ? "30px 0 0 0" : "50px 0 0 0"};
    width: 100%;    
    text-align: -moz-center;
  }
  .tfg-amz-partner-container {
    border-image: linear-gradient(rgba(82, 28, 197, 1), rgba(28, 114, 197, 1)) 50;
    border-width: 1px;
    border-style: solid;
    width: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "90%" : "50%"};
    text-align: -webkit-center;
    padding: 10px;
  }
  .partner-recommendation-text {
    font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
    font-weight: 700;
    font-size: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "16px" : "20px"};
    text-align: center;
    padding: 15px 0 0 0;
  }
  .partner-logo {
    width: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "30%" : "15%"};
    margin: 30px 0 0 0;
  }
  .partner-recommendation-description {
    margin: 30px 0 0 0;
    font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
    font-size: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "11px" : "18px"};
    text-align: center;
    background: #F6F6F6;
    border: 2px solid #BDBDBD;
    padding: 10px;
    margin-left:-3px;
    width: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "90%" : "75%"};
  }
  .partner-email-title {
    margin: 30px 0 0 0;
    font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
    font-weight: 700;
    font-size: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "14px" : "22px"};
    text-align: center;
    margin-left:-3px;
    padding: 10px 0 0 0;
  }
  .tfg-input-box {
    margin: 10px 0 0 0;
    padding: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "7px" : "10px"};
    border: 1px solid rgba(189, 189, 189, 1);
    width: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "70%" : "50%"};
    font-weight:bold;
    color: #999999;
    margin-left:-3px;
  }
  input.tfg-input-box:focus {
    outline: solid 1px rgba(189, 189, 189, 1);
  }
  input.tfg-input-box::placeholder {
    color: rgba(189, 189, 189, 1);
  }
  .tfg-input-submit {
    margin: 10px 0 0 0;
    margin-left:-3px;
    background: rgb(255, 153, 0);
    color: rgb(255, 255, 255);
    width: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "30%" : "20%"};
    border: 1px solid rgb(255, 153, 0);
    font-size: 15px;
    padding: 5px;
    border-radius: 5px;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .tfg-footer {
    background: #F6F6F6;
  }
  .tfg-footer-container {
    display:flex;
    align-items:center;
    width: 100%;
    justify-content: space-around;
    background: #F6F6F6;
    bottom:-10px;
    padding: 10px 0 30px 0;
    border-bottom: 0.1px solid rgba(189, 189, 189, 1);
  }
  .footer-left-title {
    text-align: left;
    font-weight: bold;
    font-size: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "14px" : "22px"};
    color: rgba(47, 47, 47, 0.54);
    margin: 10px 0 0 0;
  }
  .footer-left-data {
    margin: 5px 0 0 0;
  }
  .footer-left-data a {
    text-align: left;
    color: rgba(47, 47, 47, 0.54);
    font-size: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "10px" : "16px"};
    text-decoration: none;
    font-weight: bold;
  }
  .footer-left-address {
    margin: 20px 0 0 0;
    font-size: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "10px" : "18px"};
    text-align: left;
    color: rgba(47, 47, 47, 0.54);
  }
  .tfg-footer-sub {
    text-align-last: center;
    padding: 0px 15px 30px 15px;
    font-size: ${(window?.navigator?.userAgent?.includes("Mobile")) ? "12px" : "22px"};
  }
  .animate-charcter {
    padding: 15px;
    font-size: 18px;
  }
  .Success-container {
    padding: 15px;
    border:1px solid green;
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
`;
const App = () => {
  const [emailSuccess, setEmailSuccess] = useState(undefined);
  const [submitButton, setSubmitButton] = useState(false);
  useEffect(() => {
    (async () => {
    })();
  }, []);
  // console.log({window});
  console.log("window : ", window?.navigator?.userAgent?.includes("Mobile"));

  // console.log({window:window?.navigator?.userAgent?.includes("Mobile")});
  return (
    <>
      <Wrapper>
        <div className="tfg-payment-success-container">
          <div className="header-title">
            <a href="https://thefunnelguru.com/" target="_blank">
              &nbsp;&nbsp;<img src={Logo} className="logoIMG" />
            </a>
          </div>
          <div className="tfg-container-box">
            <div className="tfg-success-image-container">
              <img src={SuccessPaymentImage} className="payment-success-image" />
            </div>
            <div className="tfg-success-message-container">
              Form Submission Successful! <br /> Our Team Will Be in Touch with You Soon!
            </div>
            <div className="tfg-success-notes-container">
              “We can't be in survival mode. We have to be in growth mode.” -- Jeff Bezos.
            </div>
            <div className="partner-container">
              <div className="tfg-amz-partner-container">
                <div className="partner-recommendation-text">
                  The Funnel Guru’s Recommendation
                </div>
                <div className="partner-recommendation-logo">
                  <img src={AMZ_ARBITRAGE_LOGO} className="partner-logo" />
                </div>
                <div className="partner-recommendation-description">
                  “AMZ Online Arbitrage Provides <b>Profitable Online Arbitrage<br /> Deals and Chrome Extensions to Amazon Sellers”</b>
                </div>
                <div className="partner-email-title">
                  Enter Email Address To Get “FREE DEALS & CHROME EXTENSIONS”
                </div>
                <div className="partner-email-input">
                  {emailSuccess ? (
                    <div class="Success-container" style={{ margin: "30px", }}>
                      <div class="row">
                        <div class="col-md-12 text-center">
                          {emailSuccess?.includes("Already subscribed") ? (
                            <img src={Warning} width={"50px"} />
                          ) : (
                            <img src={Congratulation} />
                          )}
                          <h3 class="animate-charcter">{emailSuccess}</h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <input
                      className="tfg-input-box"
                      id="txtEmail"
                      placeholder="Type your email address..."
                    />
                  )}
                </div>
                {!emailSuccess && (
                  <div className="partner-submit-container">
                    <button
                      type="submit"
                      disabled={submitButton}
                      className="tfg-input-submit"
                      onClick={async () => {
                        setSubmitButton(true);
                        var emailID = document.getElementById('txtEmail');
                        console.log({ emailID: emailID })
                        console.log({ emailID: emailID?.value.trim() })
                        var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if (!filter.test(emailID?.value.trim())) {
                          setEmailSuccess(undefined);
                          setSubmitButton(false);
                          emailID.focus();
                          message.error("Please provide a valid email address")
                          return false;
                        } else {
                          await axios
                            .post(
                              "https://salespage-api.amzonlinearbitrage.com/api/v1/subscribe/tfgpartnerpage",
                              { emailID: emailID?.value.trim() }
                            )
                            .then((res) => {
                              console.log({ res });
                              if (res.data.oldUser) {
                                emailID.value = null;
                                setEmailSuccess("Already subscribed.. please contact contact@amzonlinearbitrage.com for offers")
                                message.warning("Already Subscribed..");
                                setSubmitButton(false);
                              } else {
                                emailID.value = null;
                                setEmailSuccess("We have received your email address, and the team will contact you soon. ")
                                message.success("Thank You.. Keep in Touch..");
                              }
                            })
                            .catch((err) => {
                              setEmailSuccess(undefined);
                              console.log(err);
                              setSubmitButton(false);
                              message.error("Something Wrong.. please contact - contact@amzonlinearbitrage.com");
                            });
                        }
                      }}
                    >
                      {submitButton && (
                        <img class="rotating" src={Loading} width={"16px"} />
                      )}
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="tfg-footer">
            <div className="tfg-footer-container">
              <div>
                <div className="footer-left-title">
                  Contact Us
                </div>
                <div className="footer-left-data">
                  <a href="mailto:thefunnelguru@gmail.com" target="_blank">thefunnelguru@gmail.com</a>
                </div>
                <div className="footer-left-data">
                  <a href="mailto:contact@thefunnelguru.com" target="_blank">contact@thefunnelguru.com</a>
                </div>
                <div className="footer-left-address">
                  360 Newbury St, Boston, MA, USA 02115
                </div>
              </div>
              <div>
                <div className="footer-left-title">
                  Quick Links
                </div>
                <div className="footer-left-data">
                  &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://thefunnelguru.com/restrictedcategory" target="_blank">Home</a>
                </div>
                <div className="footer-left-data">
                  &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://thefunnelguru.com/faq" target="_blank">FAQ</a>
                </div>
                <div className="footer-left-data">
                  &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://thefunnelguru.com/terms21184936" target="_blank">Refund Policy Terms</a>
                </div>
                <div className="footer-left-data">
                  &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://thefunnelguru.com/Policy" target="_blank">Privacy Policy</a>
                </div>
              </div>
            </div>
            <div className="tfg-footer-sub">
              <div className="footer-left-address">
                For all the packages, case log access is necessary to avoid back and forth email communication.
              </div>
              <div className="footer-left-address">
                {parseInt(new Date().getFullYear())} © The Funnel Guru. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
export default App;
