import React, { useContext, useEffect, useState } from "react";
import "./../../assets/header.css";
import Logo from "../../images/TFG-Logo.png";

const Header = () => {
    const [naveOpen, setNaveOpen] = useState(false);


    useEffect(() => {
    }, []);

    return (
        <>
            <div>
                <ul className="menu">
                    <li className="logo">
                        {/* <a href="https://thefunnelguru.com/restrictedcategory"
                        > */}
                            <img
                                className="header__logo"
                                src={Logo}
                            />
                        {/* </a> */}
                    </li>
                    {/* <li className="item"><a href="https://thefunnelguru.com/restrictedcategory">Home</a></li>
                    <li className="item"><a href="https://thefunnelguru.com/about-thefunnelguru1704974805485">About</a></li>
                    <li className="item"><a href="https://thefunnelguru.com/ungating-services-tfg">Services</a></li>
                    <li className="item"><a href="https://thefunnelguru.com/brands-list">Brand Ungating</a></li>
                    <li className="item"><a href="https://asinchecker.thefunnelguru.com/">Chrome Extension</a></li>
                    <li className="item"><a href="https://thefunnelguru.com/ungating-testimonials">Testimonials</a></li>
                    <li className="item"><a href="https://thefunnelguru.com/faqtfg">Faq</a></li>
                    <li className="item">
                        <a href="https://blog.thefunnelguru.com">Blog</a>
                    </li>
                    <li 
                        className="toggle"
                        onClick={async()=>{
                            let NavCondition=!naveOpen;
                            setNaveOpen(!naveOpen);
                            let elementsItems = document.getElementsByClassName("item");
                            console.log({ classList: naveOpen});
                            console.log({ elementsItems: Array.from(elementsItems) });
                            if (Array.from(elementsItems).length > 2) {
                                await Array.from(elementsItems)?.forEach(async(item) => {
                                    if (!NavCondition) {
                                        item.classList.remove('active');
                                        console.log({ classList: item.classList});
                                    } else {
                                        item.classList.add('active');
                                    }
                                });
                            }
                            let DataV=await Array.from(elementsItems)?.filter((itemValue)=>itemValue.className==="item active")
                            console.log({ className_classList: DataV});
                        }}
                    >
                        <span className="bars"></span>
                    </li> */}
                </ul>
            </div>
        </>
    );
}

export default Header;
