import React, { useContext, useEffect, useState } from "react";
import "./../../assets/footer.css";

const Footer = () => {
    return (
        <>
            <div class="footer-container">
                <div class="footercontactInfo">
                    <div class="contact-us-section">
                        <h4>Contact Us</h4>
                        <div class="email-contact">
                            <p>thefunnelguru@gmail.com</p>
                            <p>contact@thefunnelguru.com</p>
                        </div>
                        <p style={{color:"#2f2f2f8a"}}>360 Newbury St, Boston, MA, USA 02115</p>
                    </div>
                    {/* <div class="quick-link-section"> */}
                        {/* <h4>Quick Links</h4>
                        <ul>
                            <li>
                                <a href="https://thefunnelguru.com/restrictedcategory">Home</a></li>
                            <li>
                                <a href="https://thefunnelguru.com/about-thefunnelguru1704974805485">About Us</a></li>
                            <li>
                                <a href="https://thefunnelguru.com/ungating-services-tfg">Services</a></li>
                            <li>
                                <a href="https://thefunnelguru.com/faqtfg">FAQ</a></li>
                            <li>
                                <a href="https://thefunnelguru.com/terms21184936">Refund Policy Terms</a></li>
                            <li>
                                <a href="https://thefunnelguru.com/Policy">Privacy Policy</a></li>
                            <li>
                                <a href="https://blog.thefunnelguru.com/">Blog</a></li>
                        </ul> */}
                    {/* </div> */}
                </div>
                <div class="copyright-section">
                    <p>{new Date().getFullYear()} © The Funnel Guru. All Rights Reserved.</p>
                </div>
            </div>
        </>
    );
}

export default Footer;
