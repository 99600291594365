import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Layout from "../pages/Layout";
import CustomerForm from "../pages/customer_form";
import Custom from "../pages/custom";
import PopUpForm from "../pages/popupform";
import StaticForm from "../pages/static_customer_form";
import RecurringCustomerForm from "../pages/recurring_customer_form";
import TFG_AOA_landing_page from "../pages/TFG_AOA_landing_page";
import Page404 from "../pages/404";
import React, { useContext, useEffect, useState } from "react";
// import Home from "./pages/Home";
// import Blogs from "./pages/Blogs";
// import Contact from "./pages/Contact";
// import NoPage from "./pages/NoPage";

const Router=()=> {
  const [pageLoad, setPageLoad] = useState(false);
  const [URLQuery, setURLQuery] = useState();
  useEffect(() => {
    const URL = window.location.pathname;
    console.log("URL Value",URL);
    const URLQueryData = window.location.search?.split("?");
    console.log("URL Value",URLQueryData);
    console.log("URL Value",URLQueryData[1]);
    if(URL==="/thankyou" || URL==="/html" || URL==="/custom" || URL==="/popupForm" || URL==="/tfg" || URL==="/partner" ){
      setURLQuery(URLQueryData[1]);
    }else{
      window.location.replace("https://thefunnelguru.com/restrictedcategory");
    }
  }, []);   
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/thankyou" element={<CustomerForm />}/>
          <Route path="/tfg" element={<RecurringCustomerForm />}/>
          <Route path="/html" element={<StaticForm />}/>
          <Route path="/custom" element={<Custom />}/>
          <Route path="/popupForm" element={<PopUpForm />}/>
          <Route path="/partner" element={<TFG_AOA_landing_page />}/>
        </Routes>
    </BrowserRouter>
  );
}

export default Router;