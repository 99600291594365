import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SmileOutlined, InboxOutlined, QuestionCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios, { isCancel, AxiosError } from 'axios';
import * as serviceCaller from "../services/form";
import ScreenShotImage1 from "../images/screen_shot1.png";
import ScreenShotImage2 from "../images/screen_shot2.png";
import Logo from "../images/TFG-Logo.png";
import Guide from "./thankyou_guide";
import {
    Select,
    Row,
    Col,
    Steps,
    Checkbox,
    Progress,
    Form,
    Image,
    Button,
    Input,
    message,
    Upload,
    Radio,
    Space,
    Modal,
    notification,
    Empty,
    Tooltip,
    Spin
} from 'antd';
const baseURL = process.env.REACT_APP_BASEURL;
const { Dragger } = Upload;
const cloudFront = "https://dnapf9vk17vfg.cloudfront.net";

const Wrapper = styled.div`
    width:100%;
    overflow-x:hidden;
    // .steps {
    //     margin-top: 25px;
    //     margin-left: 50px;
    //     margin-right: 50px;
    // }
    .heading-Title {
        text-align: center;
        font-size: 18px;
    }
    .Form {
        // padding: 10px;
    }
    .ant-checkbox-group {
        width: 100%;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        display: unset;
    }
    .Form-Title {
        font-weight:bold;
        text-align:center;
    }
    .ant-upload-drag-icon{
        text-align:center;
    }
    legend.legend {
        width: auto;
        background: none;
        border: none;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
    }
    .ant-form-item-control-input {
        width: 100%;
    }
    .fieldset {
        width: 85%;
    }
    @media only screen and (max-width: 362px) {
        .logoIMG{
            width: 50%;
        }
        .TitleHead{
            margin: -6% 0px 6%;
            width: 118%;
            border-bottom: 2px;
            padding-bottom: 1%;
            padding-top: 2%;
            box-shadow: rgb(0 0 255 / 20%) 0px 0px 10px 0px;
        }
        .Steps{
            margin-left: -2%;
        }
        .miscPack{
            width: 100%;
        }
    }
    @media only screen and (min-width: 362px) and (max-width: 450px) {
        .logoIMG{
            width: 50%;
        }
        .TitleHead{
            margin: -6% 0px 6%;
            width: 106%;
            border-bottom: 2px;
            padding-bottom: 1%;
            padding-top: 2%;
            box-shadow: rgb(0 0 255 / 20%) 0px 0px 10px 0px;
        }
        .Steps{
            margin-left: -2%;
        }
        .miscPack{
            width: 100%;
        }
    }
    @media only screen and (min-width: 450px) and (max-width: 600px) {
        .logoIMG{
            width: 35%;
        }
        .TitleHead{
            margin: -4% 0px 6%;
            width: 103%;
            border-bottom: 2px;
            padding-bottom: 1%;
            padding-top: 2%;
            box-shadow: rgb(0 0 255 / 20%) 0px 0px 10px 0px;
        }
        .miscPack{
            width: 100%;
        }
    }
    @media only screen and (min-width: 600px) and (max-width: 768px) {
        .logoIMG{
            width: 32%;
        }
        .TitleHead{
            margin: -4% 0px 5%;
            width: 104%;
            border-bottom: 2px;
            padding-bottom: 1%;
            padding-top: 2%;
            box-shadow: rgb(0 0 255 / 20%) 0px 0px 10px 0px;
        }
        .miscPack{
            width: 100%;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
        .logoIMG{
            width: 20%;
        }
        .TitleHead{
            margin: -3% 0px 5%;
            width: 104%;
            border-bottom: 2px;
            padding-bottom: 1%;
            padding-top: 2%;
            box-shadow: rgb(0 0 255 / 20%) 0px 0px 10px 0px;
        }
        .miscPack{
            width: 100%;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .logoIMG{
            width: 20%;
        }
        .TitleHead{
            margin: -3% 0px 2%;
            width: 103%;
            border-bottom: 2px;
            padding-bottom: 1%;
            padding-top: 2%;
            box-shadow: rgb(0 0 255 / 20%) 0px 0px 10px 0px;
        }
        .miscPack{
            width: 100%;
        }
    }
    @media only screen and (min-width: 1200px) {
        .logoIMG{
            width: 15%;
        }
        .TitleHead{
            margin: -3% 0px 2%;
            width: 103%;
            border-bottom: 2px;
            padding-bottom: 1%;
            padding-top: 2%;
            box-shadow: rgb(0 0 255 / 20%) 0px 0px 10px 0px;
        }
        .miscPack{
            width: 100%;
        }
    }
    .ant-spin-nested-loading >div>.ant-spin {
        position: fixed;
        top: 0;
        inset-inline-start: 0;
        z-index: 4;
        display: block;
        width: 100%;
        min-height: -webkit-fill-available;
        max-height: 100vh;
    }
    .ant-spin .ant-spin-dot-item {
        position: absolute;
        display: block;
        width: 9px;
        height: 9px;
        background-color: #1677ff;
        border-radius: 100%;
        transform: scale(0.75);
        transform-origin: 50% 50%;
        opacity: 0.3;
        animation-name: css-dev-only-do-not-override-k83k30-antSpinMove;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-direction: alternate;
    }
`;
const CustomerForm = () => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [AppliedTimes] = Form.useForm();
    const { TextArea } = Input;
    const [current, setCurrent] = useState(0);
    const [formData, setFormData] = useState(undefined);
    const [formId, setFormId] = useState(undefined);
    const [ungating, setUngating] = useState(undefined);
    const [ImageBuffer, setImageBuffer] = useState(null);
    const [ImageBuffer2, setImageBuffer2] = useState();
    const [audioVisual, setAudioVisual] = useState();
    const [MultipleMarketplace, setMultipleMarketplace] = useState();
    const [automotive, setAutomotive] = useState();
    const [babyDiapers, setBabyDiapers] = useState();
    const [babyStrollerAndCarrier, setBabyStrollerAndCarrier] = useState();
    const [babyFeeding, setBabyFeeding] = useState();
    const [babyTopical, setBabyTopical] = useState();
    const [candleAndAromatherapy, setCandleAndAromatherapy] = useState();
    const [chargersAndBatteries, setChargersAndBatteries] = useState();
    const [DVDAndBluRayMedias, setDVDAndBluRayMedias] = useState();
    const [gardeningAndWatering, setGardeningAndWatering] = useState();
    const [hairCareAndBeautyAppliances, setHairCareAndBeautyAppliances] = useState();
    const [homeElectronics, setHomeElectronics] = useState();
    const [kidsBabyFurniture, setKidsBabyFurniture] = useState();
    const [lighting, setLighting] = useState();
    const [music, setMusic] = useState();
    const [otcMedications, setOtcMedications] = useState();
    const [personalSafetyAndHousehold, setPersonalSafetyAndHousehold] = useState();
    const [petFood, setpetFood] = useState();
    const [popularDVD, setPopularDVD] = useState();
    const [PopularMusic, setPopularMusic] = useState();
    const [PowerTools, setPowerTools] = useState();
    const [Topical, setTopicalBeauty] = useState();
    const [Watches, setWatches] = useState();
    const [WirelessAccessories, setWirelessAccessories] = useState();
    const [dietarySupplements, setDietarySupplements] = useState();
    const [groceryFoods, setGroceryFoods] = useState();
    const [usaToys, setUsaToys] = useState();
    const [petSuplies, setPetSuplies] = useState();
    const [medicaSuplies, setMedicaSuplies] = useState();
    const [petCare, setPetCare] = useState();
    const [femineHygine, setFemineHygine] = useState();
    const [babyActivityGear, setBabyActivityGear] = useState();
    const [brandUngatingName, setBrandUngatingName] = useState();
    const [responseData, setResponseData] = useState();
    const [miscPack, setMiscPack] = useState();
    const [Uploading, setUploading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [percent2, setPercent2] = useState(0);
    const [appliedTimes, setAppliedTimes] = useState(undefined);
    const [submit, setSubmit] = useState(false);
    const [customeNew, setCustomeNew] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [others, setOthers] = useState();
    const [othersValue, setOthersValue] = useState();
    const [guide1, setGuide] = useState(false);
    const [imageError, setImageError] = useState(undefined);
    const [imageError1, setImageError1] = useState(undefined);
    const [previosData, setPreviosData] = useState(false);
    const [SelectCategory, setSelectCategory] = useState(false);
    const [wufooIDData, setWufooIDData] = useState();
    const [OpenForm, setOpenForm] = useState(false);
    const [PreviousButton, setPreviousButton] = useState(false);
    const [RecurringCustomerData, setRecurringCustomerData] = useState([]);
    const [searchEmailID, setSearchEmailID] = useState();
    const [selectWufooType, setSelectWufooType] = useState("new");
    const [EmailError, setEmailError] = useState(false);
    const [EmptyUserWufoo, setEmptyUserWufoo] = useState(false);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);

    useEffect(() => {
        const URL = window.location.pathname;
        console.log({ URL });
        form.setFieldsValue(
            {
                market_place: "usa",
            }
        );
    }, []);
    useEffect(() => {
        (async () => {
            console.log("window : ", window?.location?.search.substring(1));
            if (window?.location?.search.substring(1) && !window?.location?.search.substring(1).includes("fbclid")) {
                setCustomeNew(true);
                if (window?.location?.search.substring(1) !== "new") {
                    console.log("window?.location?.search.substring(1) : ", window?.location?.search.substring(1));
                    setFormId(window?.location?.search.substring(1));
                    let SaveForm = await serviceCaller.getForm(window?.location?.search.substring(1));
                    setWufooIDData(SaveForm?.data?.wufooData);
                    console.log("SaveForm : ", SaveForm?.data?.wufooData);
                    let formValue = SaveForm?.data?.wufooData;
                    setAppliedTimes(formValue?.appliedTimes);
                    let ungatingArray = [];
                    formValue?.ungating.map((item) => {
                        ungatingArray.push(item?.category);
                        if (item?.category === "audioVisual") {
                            setAudioVisual(item?.asin);
                        }
                        if (item?.category === "automotive") {
                            setAutomotive(item?.asin);
                        }
                        if (item?.category === "babyDiapers") {
                            setBabyDiapers(item?.asin);
                        }
                        if (item?.category === "babyTopical") {
                            setBabyTopical(item?.asin);
                        }
                        if (item?.category === "candleAndAromatherapy") {
                            setCandleAndAromatherapy(item?.asin);
                        }
                        if (item?.category === "chargersAndBatteries") {
                            setChargersAndBatteries(item?.asin);
                        }
                        if (item?.category === "DVDAndBluRayMedias") {
                            setDVDAndBluRayMedias(item?.asin);
                        }
                        if (item?.category === "gardeningAndWatering") {
                            setGardeningAndWatering(item?.asin);
                        }
                        if (item?.category === "hairCareAndBeautyAppliances") {
                            setHairCareAndBeautyAppliances(item?.asin);
                        }
                        if (item?.category === "homeElectronics") {
                            setHomeElectronics(item?.asin);
                        }
                        if (item?.category === "kidsBabyFurniture") {
                            setKidsBabyFurniture(item?.asin);
                        }
                        if (item?.category === "lighting") {
                            setLighting(item?.asin);
                        }
                        if (item?.category === "music") {
                            setMusic(item?.asin);
                        }
                        if (item?.category === "otcMedications") {
                            setOtcMedications(item?.asin);
                        }
                        if (item?.category === "personalSafetyAndHousehold") {
                            setPersonalSafetyAndHousehold(item?.asin);
                        }
                        if (item?.category === "petFood") {
                            setpetFood(item?.asin);
                        }
                        if (item?.category === "PopularMusic") {
                            setPopularMusic(item?.asin);
                        }
                        if (item?.category === "PowerTools") {
                            setPowerTools(item?.asin);
                        }
                        if (item?.category === "Topical") {
                            setTopicalBeauty(item?.asin);
                        }
                        if (item?.category === "Watches") {
                            setWatches(item?.asin);
                        }
                        if (item?.category === "WirelessAccessories") {
                            setWirelessAccessories(item?.asin);
                        }
                        if (item?.category === "popularDVD") {
                            setPopularDVD(item?.asin);
                        }
                        if (item?.category === "dietarySupplements") {
                            setDietarySupplements(item?.asin);
                        }
                        if (item?.category === "babyStrollerAndCarrier") {
                            setBabyStrollerAndCarrier(item?.asin);
                        }
                        if (item?.category === "babyFeeding") {
                            setBabyFeeding(item?.asin);
                        }
                        if (item?.category === "grocery_gourmet_food") {
                            setGroceryFoods(item?.asin);
                        }
                        if (item?.category === "toys") {
                            setUsaToys(item?.asin);
                        }
                        if (item?.category === "pet_supplies") {
                            setPetSuplies(item?.asin);
                        }
                        if (item?.category === "medical_supplies") {
                            setMedicaSuplies(item?.asin);
                        }
                        if (item?.category === "pet_care") {
                            setPetCare(item?.asin);
                        }
                        if (item?.category === "femine_hygine") {
                            setFemineHygine(item?.asin);
                        }
                        if (item?.category === "baby_activity_gear") {
                            setBabyActivityGear(item?.asin);
                        }
                    })
                    if (formValue?.poa) {
                        ungatingArray.push("misc_pack");
                    }
                    if (formValue?.brandUngating?.length > 0) {
                        ungatingArray.push("brand_ungating");
                    }
                    setUngating(ungatingArray);
                    setResponseData(formValue);
                    form.setFieldsValue(
                        {
                            market_place: formValue?.AmazonMarketplace,
                            brandUngating: formValue?.brandUngating,
                            poa: formValue?.poa,
                            categories: ungatingArray,
                            account_name: formValue?.sellerAccount,
                            seller_account_email: formValue?.accountMail,
                            payment_email: formValue?.paymentMail,
                            communication_email: formValue?.CommunicationEmail,
                            customer_comment: formValue?.customer_comment,
                            hearAboutus: formValue?.hearAboutus,
                            facebook_profile: formValue?.facebookProfile,
                            others: formValue?.others,
                        }
                    );
                    AppliedTimes.setFieldsValue(
                        {
                            appliedTimes: formValue?.appliedTimes,
                        }
                    );
                    // setFormData({
                    //     account_name: formValue?.sellerAccount,
                    //     seller_account_email: formValue?.accountMail,
                    //     payment_email: formValue?.paymentMail,
                    //     communication_email: formValue?.CommunicationEmail,
                    //     customer_comment:formValue?.customer_comment,
                    //     hearAboutus: formValue?.hearAboutus,
                    //     facebook_profile: formValue?.facebookProfile,
                    //     others: formValue?.others,
                    //     appliedTimes: formValue?.appliedTimes,
                    // });
                    setOthers(formValue?.hearAboutus);
                    setOthersValue(formValue?.others);
                    setMiscPack(formValue?.poa);
                    setImageBuffer(formValue?.image);
                    setImageBuffer2(formValue?.image2);
                }
            } else {
                setCustomeNew(false)
            }
            setOpenForm(true)
        })();
    }, []);

    const getWufooCustomerData = async (value) => {
        let WufooRecurringCustomer = await serviceCaller.getWufooRecurringCustomer(value);
        console.log("WufooRecurringCustomer : ", WufooRecurringCustomer);
        if (WufooRecurringCustomer.data.data?.length > 0) {
            setRecurringCustomerData(WufooRecurringCustomer.data.data);
        } else {
            setEmptyUserWufoo(true);
        }
    }
    const getCustomerDetails = async (wufooId) => {
        console.log("WufooRecurringCustomer wufooId ", wufooId)
        let SaveForm = await serviceCaller.getSelectedForm(wufooId);
        setWufooIDData(SaveForm?.data?.wufooData);
        console.log("WufooRecurringCustomer SaveForm : ", SaveForm?.data?.wufooData);
        let formValue = SaveForm?.data?.wufooData;
        setAppliedTimes(formValue?.appliedTimes);
        let ungatingArray = [];
        formValue?.ungating.map((item) => {
            ungatingArray.push(item?.category);
            if (item?.category === "audioVisual") {
                setAudioVisual(item?.asin);
            }
            if (item?.category === "automotive") {
                setAutomotive(item?.asin);
            }
            if (item?.category === "babyDiapers") {
                setBabyDiapers(item?.asin);
            }
            if (item?.category === "babyTopical") {
                setBabyTopical(item?.asin);
            }
            if (item?.category === "candleAndAromatherapy") {
                setCandleAndAromatherapy(item?.asin);
            }
            if (item?.category === "chargersAndBatteries") {
                setChargersAndBatteries(item?.asin);
            }
            if (item?.category === "DVDAndBluRayMedias") {
                setDVDAndBluRayMedias(item?.asin);
            }
            if (item?.category === "gardeningAndWatering") {
                setGardeningAndWatering(item?.asin);
            }
            if (item?.category === "hairCareAndBeautyAppliances") {
                setHairCareAndBeautyAppliances(item?.asin);
            }
            if (item?.category === "homeElectronics") {
                setHomeElectronics(item?.asin);
            }
            if (item?.category === "kidsBabyFurniture") {
                setKidsBabyFurniture(item?.asin);
            }
            if (item?.category === "lighting") {
                setLighting(item?.asin);
            }
            if (item?.category === "music") {
                setMusic(item?.asin);
            }
            if (item?.category === "otcMedications") {
                setOtcMedications(item?.asin);
            }
            if (item?.category === "personalSafetyAndHousehold") {
                setPersonalSafetyAndHousehold(item?.asin);
            }
            if (item?.category === "petFood") {
                setpetFood(item?.asin);
            }
            if (item?.category === "PopularMusic") {
                setPopularMusic(item?.asin);
            }
            if (item?.category === "PowerTools") {
                setPowerTools(item?.asin);
            }
            if (item?.category === "Topical") {
                setTopicalBeauty(item?.asin);
            }
            if (item?.category === "Watches") {
                setWatches(item?.asin);
            }
            if (item?.category === "WirelessAccessories") {
                setWirelessAccessories(item?.asin);
            }
            if (item?.category === "popularDVD") {
                setPopularDVD(item?.asin);
            }
            if (item?.category === "dietarySupplements") {
                setDietarySupplements(item?.asin);
            }
            if (item?.category === "babyStrollerAndCarrier") {
                setBabyStrollerAndCarrier(item?.asin);
            }
            if (item?.category === "babyFeeding") {
                setBabyFeeding(item?.asin);
            }
            if (item?.category === "grocery_gourmet_food") {
                setGroceryFoods(item?.asin);
            }
            if (item?.category === "toys") {
                setUsaToys(item?.asin);
            }
            if (item?.category === "pet_supplies") {
                setPetSuplies(item?.asin);
            }
            if (item?.category === "medical_supplies") {
                setMedicaSuplies(item?.asin);
            }
            if (item?.category === "pet_care") {
                setPetCare(item?.asin);
            }
            if (item?.category === "femine_hygine") {
                setFemineHygine(item?.asin);
            }
            if (item?.category === "baby_activity_gear") {
                setBabyActivityGear(item?.asin);
            }
        })
        if (formValue?.poa) {
            ungatingArray.push("misc_pack");
        }
        if (formValue?.brandUngating?.length > 0) {
            ungatingArray.push("brand_ungating");
        }
        setUngating(ungatingArray);
        setResponseData(formValue);
        form.setFieldsValue(
            {
                market_place: formValue?.AmazonMarketplace,
                brandUngating: formValue?.brandUngating,
                poa: formValue?.poa,
                categories: ungatingArray,
                account_name: formValue?.sellerAccount,
                seller_account_email: formValue?.accountMail,
                payment_email: formValue?.paymentMail,
                communication_email: formValue?.CommunicationEmail,
                customer_comment: formValue?.customer_comment,
                hearAboutus: formValue?.hearAboutus,
                facebook_profile: formValue?.facebookProfile,
                others: formValue?.others,
            }
        );
        AppliedTimes.setFieldsValue(
            {
                appliedTimes: formValue?.appliedTimes,
            }
        );
        // setFormData({
        //     account_name: formValue?.sellerAccount,
        //     seller_account_email: formValue?.accountMail,
        //     payment_email: formValue?.paymentMail,
        //     communication_email: formValue?.CommunicationEmail,
        //     customer_comment:formValue?.customer_comment,
        //     hearAboutus: formValue?.hearAboutus,
        //     facebook_profile: formValue?.facebookProfile,
        //     others: formValue?.others,
        //     appliedTimes: formValue?.appliedTimes,
        // });
        setOthers(formValue?.hearAboutus);
        setOthersValue(formValue?.others);
        setMiscPack(formValue?.poa);
        setImageBuffer(formValue?.image);
        setImageBuffer2(formValue?.image2);
    }

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    let arrayBufferToBase64 = (buffer) => {
        let binary = '';
        console.log({ buffer });
        let bytes = new Uint8Array(buffer);
        console.log({ bytes });
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };
    const resetForm = () => {
        setFormData(undefined);
        setUngating(undefined);
        setPreviosData(undefined);
        setResponseData(undefined);
        setMiscPack(undefined);
        setBabyActivityGear(undefined);
        setFemineHygine(undefined);
        setPetCare(undefined);
        setMedicaSuplies(undefined);
        setPetSuplies(undefined);
        setUsaToys(undefined);
        setGroceryFoods(undefined);
        setBabyFeeding(undefined);
        setBabyStrollerAndCarrier(undefined);
        setDietarySupplements(undefined);
        setPopularDVD(undefined);
        setWirelessAccessories(undefined);
        setWatches(undefined);
        setTopicalBeauty(undefined);
        setPowerTools(undefined);
        setPopularMusic(undefined);
        setpetFood(undefined);
        setPersonalSafetyAndHousehold(undefined);
        setOtcMedications(undefined);
        setMusic(undefined);
        setLighting(undefined);
        setKidsBabyFurniture(undefined);
        setHomeElectronics(undefined);
        setHairCareAndBeautyAppliances(undefined);
        setGardeningAndWatering(undefined);
        setDVDAndBluRayMedias(undefined);
        setChargersAndBatteries(undefined);
        setCandleAndAromatherapy(undefined);
        setBabyTopical(undefined);
        setBabyDiapers(undefined);
        setAutomotive(undefined);
        setAudioVisual(undefined);
        setWufooIDData(undefined);
        setAppliedTimes(undefined);
        form.resetFields();
        AppliedTimes.resetFields();
        setOthers(undefined);
        setOthersValue(undefined);
        setImageBuffer(undefined);
        setImageBuffer2(undefined);
    }

    return (
        <Wrapper>
            <Helmet>
                <script type="text/javascript" async src="https://static.leaddyno.com/js"></script>
                <script
                    async
                    dangerouslySetInnerHTML={{
                        __html: `LeadDyno.key = "856f412d3c524ec6376101bd00f721880aa41e2b";
                        LeadDyno.recordVisit();
                        LeadDyno.autoWatch();`,
                    }}
                />
            </Helmet>
            {(formId && !wufooIDData) ? (
                <>
                    <div style={{ margin: "16%" }}>
                        <Empty
                            description="Link  Expired"
                        />
                    </div>
                </>
            ) : OpenForm && (
                <>
                    <Spin 
                        spinning={imageUploadLoading} 
                        tip={
                            <div style={{fontWeight:"450",fontSize:"18px",color:"#1677ff"}}>
                                Please wait while your <b>uploaded screenshot</b> is being processed.
                            </div>
                        } 
                        fullscreen={true}
                    >
                        <div style={{ paddingBottom: "15%" }}>
                            <Row
                                gutter={24}
                                align="middle"
                                className="TitleHead"
                            // style={{ 
                            //     width:"103%",
                            //     borderBottom: "2px", 
                            //     margin: "-2% 0px 2%",
                            //     paddingBottom: "1%", 
                            //     paddingTop: "1%", 
                            //     // marginBottom: "2%", 
                            //     boxShadow: "0 0 10px 0 rgb(0 0 255 / 20%)" 
                            // }}
                            >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                                        <img
                                            src={Logo}
                                            className="logoIMG"
                                        />
                                        <div>
                                            <span style={{ fontSize: "160%", fontWeight: "bold" }}>TheFunnelGuru Ungating Form</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {/* {!formId && (
                            <>
                                <Row gutter={24}>
                                    <Col xs={0} sm={2} md={8} lg={8} xl={8} xxl={8} />
                                    <Col xs={24} sm={20} md={12} lg={12} xl={12} xxl={12} style={{padding:"0px",textAlign:"justify",paddingLeft:"5%",paddingBottom:"3%"}}>
                                        <div>
                                            {current === 0 && (
                                                <>
                                                    <div>
                                                        <Radio.Group 
                                                            onChange={async(e)=>{
                                                                await resetForm();
                                                                console.log("E value : ",e.target.value);
                                                                setSelectWufooType(e.target.value);
                                                            }} 
                                                            value={selectWufooType}
                                                        >
                                                        <Radio value={"new"}>
                                                            <span style={{color:"black",fontWeight:"bold",fontSize:"12px"}}>{"New Customer"}</span>
                                                        </Radio>
                                                        <Radio value={"search"}>
                                                            <Row>
                                                                <Col xs={24} sm={10} md={10} lg={11} xl={11} xxl={11} style={{ padding: "0px" }}>
                                                                    <div>
                                                                        <span style={{color:"black",fontWeight:"bold",fontSize:"12px"}}>{"Seller Central EmailId Only"}</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={14} md={14} lg={13} xl={13} xxl={13}>
                                                                    <div style={{display:"flex",alignItems:"center",width:"100%"}}>
                                                                        <Select
                                                                            disabled={selectWufooType==="new"}
                                                                            showSearch
                                                                            allowClear
                                                                            size="small"
                                                                            placeholder={selectWufooType==="new"?"Existing Customer":"Search a Seller Email Address"}
                                                                            optionFilterProp="label"
                                                                            style={{ width: "100%",textAlign:"justify" }}
                                                                            onChange={async(wufoo)=>{
                                                                                await resetForm();
                                                                                await getCustomerDetails(wufoo);
                                                                            }}
                                                                            onSearch={async(value)=>{
                                                                                setEmailError(false);
                                                                                if(value){
                                                                                    setSearchEmailID(value);
                                                                                }
                                                                            }}
                                                                        >
                                                                            {RecurringCustomerData?.length > 0 &&(
                                                                                <>
                                                                                    {RecurringCustomerData?.map((item)=>(
                                                                                        <Option 
                                                                                            key={item?._id}
                                                                                            value={item?._id}
                                                                                            label={`${item?.wufoo} ${item?.accountMail}`}
                                                                                        >
                                                                                            <div>
                                                                                                <div>
                                                                                                    {item?.accountMail}
                                                                                                </div>
                                                                                                <div>
                                                                                                    {item?.wufoo ?? "No Data"}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Option>
                                                                                    ))}
                                                                                </>
                                                                            )}
                                                                        </Select>
                                                                        <Button 
                                                                            htmlType="submit" 
                                                                            type="primary" 
                                                                            size="small"
                                                                            onClick={async()=>{
                                                                                setEmptyUserWufoo(false);
                                                                                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(searchEmailID)){
                                                                                    await getWufooCustomerData(searchEmailID)
                                                                                }else{
                                                                                    setEmailError(true);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <span>{"Search"}</span>
                                                                            </div>
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                    {(EmailError && selectWufooType !=="new") ? (
                                                        <div style={{marginTop:"3%"}}>
                                                            <span style={{color:"red"}}>{"Please Enter a valid Email Address"}</span>
                                                        </div>
                                                    ):(EmptyUserWufoo && selectWufooType !=="new") ? (
                                                        <div style={{marginTop:"3%"}}>
                                                            <span style={{color:"red"}}>{"There is no records found for this EmailId"}</span>
                                                        </div>
                                                    ): (RecurringCustomerData?.length>0 && !wufooIDData && selectWufooType !=="new") && (
                                                        <div style={{marginTop:"3%"}}>
                                                            <span style={{color:"orange "}}>{"You can select existing data from above select box"}</span>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <Col xs={0} sm={2} md={4} lg={4} xl={4} xxl={4} />
                                </Row>
                            </>
                        )} */}
                            {/* {(selectWufooType === "new" || wufooIDData)&&( */}
                            <>
                                <Row gutter={24} style={{ marginTop: "2%" }}>
                                    <Col xs={0} sm={2} md={6} lg={6} xl={6} xxl={6} />
                                    <Col xs={24} sm={20} md={12} lg={12} xl={12} xxl={12} style={{ padding: "0px" }}>
                                        <div style={{ cursor: "default", marginLeft: "0%" }}>
                                            <Steps
                                                className="Steps"
                                                progressDot
                                                direction="horizontal"
                                                current={current}
                                                responsive={false}
                                                items={[
                                                    {
                                                        title: 'Step 1',
                                                    },
                                                    {
                                                        title: 'Step 2',
                                                    },
                                                    {
                                                        title: 'Summary',
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={0} sm={2} md={6} lg={6} xl={6} xxl={6} />
                                </Row>
                                {wufooIDData?.rejectedFields?.length > 0 && formId && (
                                    <div style={{ border: "2px solid red", padding: "10px", width: "75%", marginLeft: "12%", marginTop: "2%" }}>
                                        <Row align={"middle"} style={{ alignItems: "baseline", textTransform: "capitalize" }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                                Comments : <b> {wufooIDData?.rejectedComments}</b>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ paddingTop: "10px" }}>
                                            </Col>
                                            <div style={{ overflowWrap: "break-word", width: "200%" }}>
                                                {wufooIDData?.rejectedFields?.map((item) => (
                                                    <>
                                                        <div style={{ padding: "0px", fontSize: "12px" }}>
                                                            <span style={{ color: "red" }}>*</span>{item.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()} &nbsp;&nbsp;
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </Row>
                                    </div>
                                )}
                                <div className="Form">
                                    <div style={{ marginTop: "50px" }}>
                                        <Form
                                            form={form}
                                            name="register"
                                            requiredMark={true}
                                            colon={false}
                                            onFinish={async (values) => {
                                                console.log({ formData });
                                                console.log({ values });
                                                console.log({ ungating });
                                                let AppliedTimesForm = AppliedTimes.getFieldValue();
                                                form.setFieldsValue(
                                                    {
                                                        market_place: formData?.market_place,
                                                        categories: formData?.categories,
                                                        account_name: formData?.account_name,
                                                        seller_account_email: formData?.seller_account_email,
                                                        payment_email: formData?.payment_email,
                                                        communication_email: formData?.communication_email,
                                                        customer_comment: formData?.customer_comment,
                                                        hearAboutus: formData?.hearAboutus,
                                                        facebook_profile: formData?.facebook_profile,
                                                        brandUngating: formData?.brandUngating,
                                                        poa: formData?.poa,
                                                        others: formData?.others,
                                                        appliedTimes: AppliedTimesForm?.appliedTimes,
                                                        file: null,
                                                        file2: null,
                                                    }
                                                );
                                                console.log({ responseData });
                                                if (responseData) {
                                                    form.setFieldValue(responseData);
                                                }
                                                const formValue = { ...values, ...formData };
                                                setFormData(formValue);
                                                console.log("PreviousButton : ", PreviousButton);
                                                if (!PreviousButton) {
                                                    setCurrent(current + 1);
                                                    setPreviousButton(false);
                                                }
                                            }}
                                        >
                                            {current === 0 ? (
                                                <>
                                                    <Row>
                                                        <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} style={{ padding: "0px" }} />
                                                        <Col xs={20} sm={8} md={8} lg={4} xl={4} xxl={4} style={{ padding: "0px" }}>
                                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                                <span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                                                Amazon Marketplace
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} style={{ padding: "0px" }} />
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} style={{ padding: "0px" }} />
                                                        <Col xs={20} sm={12} md={12} lg={6} xl={6} xxl={4}>
                                                            {/* <Col span={6}> */}
                                                            <Form.Item
                                                                name="market_place"
                                                                rules={[{
                                                                    required: true,
                                                                    message: "Amazon Marketplace Required"
                                                                }]}
                                                            >
                                                                <Radio.Group
                                                                    onChange={() => {
                                                                        form.setFieldsValue({
                                                                            multiple_market_place: undefined
                                                                        })
                                                                    }}
                                                                >
                                                                    <Radio value={"usa"}>USA</Radio>
                                                                    <Radio value={"uk"}>UK</Radio>
                                                                    <Radio value={"ca"}>CA</Radio>
                                                                    <Radio value={"jp"}>JP</Radio>
                                                                    <Radio value={"multiple_market_places"}>
                                                                        <Form.Item
                                                                            name="multiple_market_place"
                                                                            rules={[{
                                                                                required: form.getFieldsValue().market_place === "multiple_market_places" ? true : false,
                                                                                message: "Please Enter Multiple-Markrtplace"
                                                                            }]}
                                                                            alignItems="center"
                                                                        >
                                                                            <Input
                                                                                size="small"
                                                                                placeholder="Multiple-Markrtplace"
                                                                                style={{ marginTop: "22px" }}
                                                                                onChange={(e) => {
                                                                                    console.log("e?.target?.value : ", e?.target?.value);
                                                                                    let formD = form.getFieldsValue();
                                                                                    console.log({ formD });
                                                                                    let MultipleMarketplaceValue = e?.target?.value;
                                                                                    setMultipleMarketplace(MultipleMarketplaceValue);
                                                                                }}
                                                                            />
                                                                        </Form.Item>
                                                                    </Radio>
                                                                    {/* <Radio value={"multiple_market_places"}>Multiple Market places</Radio> */}
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7}></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7}></Col>
                                                        <Col xs={20} sm={20} md={22} lg={15} xl={12} xxl={12}>
                                                            <Form.Item
                                                                name="categories"
                                                                rules={[{
                                                                    required: true,
                                                                    message: "Categories or Brand Required"
                                                                }]}
                                                            >
                                                                <Checkbox.Group
                                                                    onChange={(val) => {
                                                                        if (val?.length <= 2) {
                                                                            if (val?.includes("brand_ungating") && val?.includes("misc_pack")) {
                                                                                setSelectCategory(true)
                                                                            } else if ((val?.includes("brand_ungating") || val?.includes("misc_pack")) && val?.length === 1) {
                                                                                setSelectCategory(true)
                                                                            } else {
                                                                                setSelectCategory(false)
                                                                            }
                                                                        }
                                                                        console.log("groceryFoods : ", groceryFoods);
                                                                        if (ungating?.includes("audioVisual")) {
                                                                            if (!val?.includes("audioVisual")) {
                                                                                setAudioVisual(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("automotive")) {
                                                                            if (!val?.includes("automotive")) {
                                                                                setAutomotive(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("babyDiapers")) {
                                                                            if (!val?.includes("babyDiapers")) {
                                                                                setBabyDiapers(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("babyTopical")) {
                                                                            if (!val?.includes("babyTopical")) {
                                                                                setBabyTopical(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("candleAndAromatherapy")) {
                                                                            if (!val?.includes("candleAndAromatherapy")) {
                                                                                setCandleAndAromatherapy(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("chargersAndBatteries")) {
                                                                            if (!val?.includes("chargersAndBatteries")) {
                                                                                setChargersAndBatteries(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("DVDAndBluRayMedias")) {
                                                                            if (!val?.includes("DVDAndBluRayMedias")) {
                                                                                setDVDAndBluRayMedias(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("gardeningAndWatering")) {
                                                                            if (!val?.includes("gardeningAndWatering")) {
                                                                                setGardeningAndWatering(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("hairCareAndBeautyAppliances")) {
                                                                            if (!val?.includes("hairCareAndBeautyAppliances")) {
                                                                                setHairCareAndBeautyAppliances(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("homeElectronics")) {
                                                                            if (!val?.includes("homeElectronics")) {
                                                                                setHomeElectronics(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("kidsBabyFurniture")) {
                                                                            if (!val?.includes("kidsBabyFurniture")) {
                                                                                setKidsBabyFurniture(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("lighting")) {
                                                                            if (!val?.includes("lighting")) {
                                                                                setLighting(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("music")) {
                                                                            if (!val?.includes("music")) {
                                                                                setMusic(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("otcMedications")) {
                                                                            if (!val?.includes("otcMedications")) {
                                                                                setOtcMedications(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("personalSafetyAndHousehold")) {
                                                                            if (!val?.includes("personalSafetyAndHousehold")) {
                                                                                setPersonalSafetyAndHousehold(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("petFood")) {
                                                                            if (!val?.includes("petFood")) {
                                                                                setpetFood(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("PopularMusic")) {
                                                                            if (!val?.includes("PopularMusic")) {
                                                                                setPopularMusic(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("PowerTools")) {
                                                                            if (!val?.includes("PowerTools")) {
                                                                                setPowerTools(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("Topical")) {
                                                                            if (!val?.includes("Topical")) {
                                                                                setTopicalBeauty(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("Watches")) {
                                                                            if (!val?.includes("Watches")) {
                                                                                setWatches(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("WirelessAccessories")) {
                                                                            if (!val?.includes("WirelessAccessories")) {
                                                                                setWirelessAccessories(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("popularDVD")) {
                                                                            if (!val?.includes("popularDVD")) {
                                                                                setPopularDVD(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("dietarySupplements")) {
                                                                            if (!val?.includes("dietarySupplements")) {
                                                                                setDietarySupplements(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("babyStrollerAndCarrier")) {
                                                                            if (!val?.includes("babyStrollerAndCarrier")) {
                                                                                setBabyStrollerAndCarrier(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("babyFeeding")) {
                                                                            if (!val?.includes("babyFeeding")) {
                                                                                setBabyFeeding(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("grocery_gourmet_food")) {
                                                                            if (!val?.includes("grocery_gourmet_food")) {
                                                                                setGroceryFoods(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("toys")) {
                                                                            if (!val?.includes("toys")) {
                                                                                setUsaToys(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("pet_supplies")) {
                                                                            if (!val?.includes("pet_supplies")) {
                                                                                setPetSuplies(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("medical_supplies")) {
                                                                            if (!val?.includes("medical_supplies")) {
                                                                                setMedicaSuplies(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("pet_care")) {
                                                                            if (!val?.includes("pet_care")) {
                                                                                setPetCare(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("femine_hygine")) {
                                                                            if (!val?.includes("femine_hygine")) {
                                                                                setFemineHygine(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("baby_activity_gear")) {
                                                                            if (!val?.includes("baby_activity_gear")) {
                                                                                setBabyActivityGear(undefined);
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("brand_ungating")) {
                                                                            if (!val?.includes("brand_ungating")) {
                                                                                setBrandUngatingName(undefined);
                                                                                form.setFieldsValue(
                                                                                    {
                                                                                        brandUngating: undefined,
                                                                                    }
                                                                                );
                                                                            }
                                                                        }
                                                                        if (ungating?.includes("misc_pack")) {
                                                                            if (!val?.includes("misc_pack")) {
                                                                                setMiscPack(undefined);
                                                                            }
                                                                        }
                                                                        setUngating(val)
                                                                    }}
                                                                >
                                                                    <Row style={{ alignItems: "normal" }}>
                                                                        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                                                                            <Checkbox value="brand_ungating">
                                                                                <b>Brand Ungating</b>
                                                                            </Checkbox>
                                                                            {ungating?.includes("brand_ungating") && (
                                                                                <Form.List
                                                                                    name="brandUngating"
                                                                                    initialValue={
                                                                                        [
                                                                                            {
                                                                                                brand: undefined,
                                                                                                asin: undefined,
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    {(fields, { add, remove }) => (
                                                                                        <Row>
                                                                                            {fields.map(({ key, name, ...restField }) => (
                                                                                                <>
                                                                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                                                                                        <Form.Item
                                                                                                            {...restField}
                                                                                                            name={[name, 'brand']}
                                                                                                            rules={[
                                                                                                                {
                                                                                                                    required: true,
                                                                                                                    message: 'Missing Brand name',
                                                                                                                },
                                                                                                            ]}
                                                                                                        >
                                                                                                            <Input
                                                                                                                style={{ width: "100%" }}
                                                                                                                placeholder="Brand Name"
                                                                                                            />
                                                                                                        </Form.Item>
                                                                                                    </Col>
                                                                                                    <Col xs={0} sm={0} md={1} lg={1} xl={1} xxl={1} />
                                                                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                                                                                        <Form.Item
                                                                                                            {...restField}
                                                                                                            name={[name, 'asin']}
                                                                                                            rules={[
                                                                                                                {
                                                                                                                    required: true,
                                                                                                                    message: 'Asin Missing',
                                                                                                                },
                                                                                                            ]}
                                                                                                        >
                                                                                                            <Input
                                                                                                                style={{ width: "100%" }}
                                                                                                                placeholder="Asin"
                                                                                                            />
                                                                                                        </Form.Item>
                                                                                                    </Col>
                                                                                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                                                                                                        {key > 0 && (
                                                                                                            <Tooltip title={`Remove ${key + 1}${key === 1 ? "nd" : key === 2 ? "rd" : "th"} Row`}>
                                                                                                                <Button
                                                                                                                    type="link"
                                                                                                                    onClick={() =>
                                                                                                                        remove(name)}
                                                                                                                    block
                                                                                                                    icon={<MinusCircleOutlined />}
                                                                                                                />
                                                                                                            </Tooltip>
                                                                                                        )}
                                                                                                    </Col>
                                                                                                </>
                                                                                                // <Space
                                                                                                //     key={key}
                                                                                                //     style={{
                                                                                                //         display: 'flex',
                                                                                                //         marginBottom: 8,
                                                                                                //     }}
                                                                                                //     align="baseline"
                                                                                                // >
                                                                                                //     <Row>
                                                                                                //     </Row>
                                                                                                // </Space>
                                                                                            ))}
                                                                                            <Col xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                                                                                                <Tooltip title={`Add a new Line`}>
                                                                                                    <Form.Item>
                                                                                                        <Button
                                                                                                            // style={{ width: "40%" }}
                                                                                                            type="link"
                                                                                                            onClick={() => {
                                                                                                                add({
                                                                                                                    brand: null,
                                                                                                                    asin: null,
                                                                                                                });
                                                                                                                let FormData = form.getFieldsValue();
                                                                                                                console.log("FormData : ", FormData);
                                                                                                            }}
                                                                                                            block
                                                                                                            icon={<PlusOutlined />}
                                                                                                        >
                                                                                                            {/* Add field */}
                                                                                                        </Button>
                                                                                                    </Form.Item>
                                                                                                </Tooltip>
                                                                                            </Col>
                                                                                            <Col xs={0} sm={0} md={1} lg={3} xl={3} xxl={3} />
                                                                                        </Row>
                                                                                    )}
                                                                                </Form.List>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                                                            <Checkbox value="misc_pack">
                                                                                <b>POA</b>
                                                                                {ungating?.includes("misc_pack") && (
                                                                                    <>
                                                                                        <Form.Item
                                                                                            name="poa"
                                                                                            rules={[{
                                                                                                required: true,
                                                                                                message: "POA Required"
                                                                                            }]}
                                                                                        >
                                                                                            <TextArea
                                                                                                // className="miscPack"
                                                                                                rows={4}
                                                                                                style={{ width: "280px" }}
                                                                                                placeholder="POA"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    console.log({ asinValue });
                                                                                                    setMiscPack(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </>
                                                                                )}
                                                                            </Checkbox>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row justify={"space-between"} align={"middle"} style={{ alignItems: "baseline", marginTop: "2%" }}>
                                                                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="audioVisual">
                                                                                        <span>Audio Visual</span>
                                                                                        {ungating?.includes("audioVisual") && (
                                                                                            <Input
                                                                                                value={audioVisual}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setAudioVisual(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="automotive">
                                                                                        <span>Automotive</span>
                                                                                        {ungating?.includes("automotive") && (
                                                                                            <Input
                                                                                                value={automotive}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setAutomotive(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="baby_activity_gear">
                                                                                        <span>Baby Activity Gear</span>
                                                                                        {ungating?.includes("baby_activity_gear") && (
                                                                                            <Input
                                                                                                value={babyActivityGear}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setBabyActivityGear(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="babyDiapers">
                                                                                        <span>Baby Diapers</span>
                                                                                        {ungating?.includes("babyDiapers") && (
                                                                                            <Input
                                                                                                value={babyDiapers}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setBabyDiapers(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="babyFeeding">
                                                                                        <span>Baby Feeding</span>
                                                                                        {ungating?.includes("babyFeeding") && (
                                                                                            <Input
                                                                                                value={babyFeeding}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setBabyFeeding(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="babyStrollerAndCarrier">
                                                                                        <span>Baby Stroller and Carrier</span>
                                                                                        {ungating?.includes("babyStrollerAndCarrier") && (
                                                                                            <Input
                                                                                                value={babyStrollerAndCarrier}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setBabyStrollerAndCarrier(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="babyTopical">
                                                                                        <span>Baby Topical</span>
                                                                                        {ungating?.includes("babyTopical") && (
                                                                                            <Input
                                                                                                value={babyTopical}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setBabyTopical(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="candleAndAromatherapy">
                                                                                        <span>Candle and Aromatherapy</span>
                                                                                        {ungating?.includes("candleAndAromatherapy") && (
                                                                                            <Input
                                                                                                value={candleAndAromatherapy}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setCandleAndAromatherapy(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="chargersAndBatteries">
                                                                                        <span>Chargers and Batteries</span>
                                                                                        {ungating?.includes("chargersAndBatteries") && (
                                                                                            <Input
                                                                                                value={chargersAndBatteries}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setChargersAndBatteries(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="DVDAndBluRayMedias">
                                                                                        <span>DVD and BluRay Medias</span>
                                                                                        {ungating?.includes("DVDAndBluRayMedias") && (
                                                                                            <Input
                                                                                                value={DVDAndBluRayMedias}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setDVDAndBluRayMedias(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="dietarySupplements">
                                                                                        <span>Dietary Supplements</span>
                                                                                        {ungating?.includes("dietarySupplements") && (
                                                                                            <Input
                                                                                                value={dietarySupplements}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setDietarySupplements(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="femine_hygine">
                                                                                        <span>Femine Hygine</span>
                                                                                        {ungating?.includes("femine_hygine") && (
                                                                                            <Input
                                                                                                value={femineHygine}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setFemineHygine(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="gardeningAndWatering">
                                                                                        <span>Gardening and Watering</span>
                                                                                        {ungating?.includes("gardeningAndWatering") && (
                                                                                            <Input
                                                                                                value={gardeningAndWatering}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setGardeningAndWatering(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="grocery_gourmet_food">
                                                                                        <span>Grocery and Gourmet Food</span>
                                                                                        {ungating?.includes("grocery_gourmet_food") && (
                                                                                            <Input
                                                                                                value={groceryFoods}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setGroceryFoods(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="hairCareAndBeautyAppliances">
                                                                                        <span>Hair Care and Beauty Appliances</span>
                                                                                        {ungating?.includes("hairCareAndBeautyAppliances") && (
                                                                                            <Input
                                                                                                value={hairCareAndBeautyAppliances}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setHairCareAndBeautyAppliances(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="homeElectronics">
                                                                                        <span>Home Electronics</span>
                                                                                        {ungating?.includes("homeElectronics") && (
                                                                                            <Input
                                                                                                value={homeElectronics}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setHomeElectronics(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="kidsBabyFurniture">
                                                                                        <span>Kids Baby Furniture</span>
                                                                                        {ungating?.includes("kidsBabyFurniture") && (
                                                                                            <Input
                                                                                                value={kidsBabyFurniture}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setKidsBabyFurniture(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="lighting">
                                                                                        <span>Lighting</span>
                                                                                        {ungating?.includes("lighting") && (
                                                                                            <Input
                                                                                                value={lighting}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setLighting(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="music">
                                                                                        <span>Music</span>
                                                                                        {ungating?.includes("music") && (
                                                                                            <Input
                                                                                                value={music}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setMusic(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="medical_supplies">
                                                                                        <span>Medical Supplies</span>
                                                                                        {ungating?.includes("medical_supplies") && (
                                                                                            <Input
                                                                                                value={medicaSuplies}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setMedicaSuplies(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="otcMedications">
                                                                                        <span>OTC Medications</span>
                                                                                        {ungating?.includes("otcMedications") && (
                                                                                            <Input
                                                                                                value={otcMedications}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setOtcMedications(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="personalSafetyAndHousehold">
                                                                                        <span>Personal Safety and Household</span>
                                                                                        {ungating?.includes("personalSafetyAndHousehold") && (
                                                                                            <Input
                                                                                                value={personalSafetyAndHousehold}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setPersonalSafetyAndHousehold(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="petFood">
                                                                                        <span>Pet Food</span>
                                                                                        {ungating?.includes("petFood") && (
                                                                                            <Input
                                                                                                value={petFood}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setpetFood(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="PopularMusic">
                                                                                        <span>Popular Music</span>
                                                                                        {ungating?.includes("PopularMusic") && (
                                                                                            <Input
                                                                                                value={PopularMusic}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setPopularMusic(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="PowerTools">
                                                                                        <span>Power Tools</span>
                                                                                        {ungating?.includes("PowerTools") && (
                                                                                            <Input
                                                                                                value={PowerTools}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setPowerTools(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row><Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="popularDVD">
                                                                                        <span>Popular DVDs</span>
                                                                                        {ungating?.includes("popularDVD") && (
                                                                                            <Input
                                                                                                value={popularDVD}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setPopularDVD(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="pet_supplies">
                                                                                        <span>Pet Supplies</span>
                                                                                        {ungating?.includes("pet_supplies") && (
                                                                                            <Input
                                                                                                value={petSuplies}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setPetSuplies(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="pet_care">
                                                                                        <span>Pet Care</span>
                                                                                        {ungating?.includes("pet_care") && (
                                                                                            <Input
                                                                                                value={petCare}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setPetCare(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="Topical">
                                                                                        <span>Topical (AKA Beauty)</span>
                                                                                        {ungating?.includes("Topical") && (
                                                                                            <Input
                                                                                                value={Topical}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setTopicalBeauty(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="toys">
                                                                                        <span>Toys</span>
                                                                                        {ungating?.includes("toys") && (
                                                                                            <Input
                                                                                                value={usaToys}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setUsaToys(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="Watches">
                                                                                        <span>Watches</span>
                                                                                        {ungating?.includes("Watches") && (
                                                                                            <Input
                                                                                                value={Watches}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setWatches(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <Checkbox value="WirelessAccessories">
                                                                                        <span>Wireless Accessories</span>
                                                                                        {ungating?.includes("WirelessAccessories") && (
                                                                                            <Input
                                                                                                value={WirelessAccessories}
                                                                                                placeholder="Asin"
                                                                                                onChange={(e) => {
                                                                                                    let asinValue = e?.target?.value;
                                                                                                    setWirelessAccessories(asinValue);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    {" "}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    {" "}
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Checkbox.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={2} md={0} lg={5} xl={5} xxl={5}></Col>
                                                    </Row>
                                                </>
                                            ) : current === 1 && (
                                                <>
                                                    <Row>
                                                        <Col xs={2} sm={2} md={2} lg={4} xl={4} xxl={4} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                                Seller Account Display Name
                                                                <span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <Form.Item
                                                                name="account_name"
                                                                rules={[{
                                                                    required: true,
                                                                    message: "Seller Account Name Required"
                                                                }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        {/* <Col span={1} /> */}
                                                        <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                                Facebook Profile Name
                                                                {/* <span style={{ fontWeight: "bold", color: "red" }}>*</span> */}
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <Form.Item
                                                                name="facebook_profile"
                                                                rules={[{
                                                                    required: false,
                                                                    message: "Facebook Profile Name is Required"
                                                                }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={3} xl={3} xxl={3} />
                                                    </Row>
                                                    <Row>
                                                        <Col xs={2} sm={2} md={2} lg={4} xl={4} xxl={4} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                                Seller Account Email Address<span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <Form.Item name="seller_account_email"
                                                                rules={[
                                                                    {
                                                                        type: 'email',
                                                                        message: 'The input is not valid Email Address!',
                                                                    },
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input your Seller Account Email Address!',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                                Payment Email Address &nbsp;
                                                                <Tooltip title={"Mention the email address which you used during the website checkout process"}>
                                                                    <div>
                                                                        <QuestionCircleOutlined />
                                                                    </div>
                                                                </Tooltip>&nbsp;
                                                                <span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <Form.Item name="payment_email"
                                                                rules={[
                                                                    {
                                                                        type: 'email',
                                                                        message: 'The input is not valid Email Address!',
                                                                    },
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input your Payment Email Address!',
                                                                    },
                                                                ]}>
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={3} xl={3} xxl={3} />
                                                    </Row>



                                                    <Row>
                                                        <Col xs={2} sm={2} md={2} lg={4} xl={4} xxl={4} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                                Communication Email Address &nbsp;
                                                                <Tooltip title={"All the communication from us will be send to this email address only, so please make sure to provide the correct email address"}>
                                                                    <div>
                                                                        <QuestionCircleOutlined />
                                                                    </div>
                                                                </Tooltip>&nbsp;
                                                                <span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <Form.Item name="communication_email"
                                                                rules={[
                                                                    {
                                                                        type: 'email',
                                                                        message: 'The input is not valid Email Address!',
                                                                    },
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input your Communication Email Address!',
                                                                    },
                                                                ]}>
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                                Comments (optional)
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <Form.Item name="customer_comment"
                                                            // rules={[
                                                            //     {
                                                            //     required: true,
                                                            //     message: 'Please input your Payment Email Address!',
                                                            //     },
                                                            // ]}
                                                            >
                                                                <TextArea
                                                                    rows={2}
                                                                    placeholder="Comments..."
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={3} xl={3} xxl={3} />
                                                    </Row>
                                                    {/* <Row>
                                                        <Col xs={2} sm={2} md={2} lg={4} xl={4} xxl={4} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                                Communication Email <span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                            <Form.Item name="communication_email"
                                                                rules={[
                                                                    {
                                                                        type: 'email',
                                                                        required: true
                                                                    },
                                                                ]}>
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                        <Col xs={22} sm={11} md={11} lg={4} xl={4} xxl={4}>
                                                        </Col>
                                                        <Col xs={2} sm={0} md={0} lg={3} xl={3} xxl={3} />
                                                    </Row> */}
                                                    <Row justify={"space-between"} style={{ alignItems: "self-start" }}>
                                                        <Col xs={2} sm={2} md={2} lg={4} xl={4} xxl={4} />
                                                        <Col xs={22} sm={22} md={22} lg={8} xl={8} xxl={8}>
                                                            <>
                                                                {/* <Row>
                                                                    <Col xs={22} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                        <div style={{ display: "flex", alignContent: "center" }}>
                                                                            Communication Email Address<span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                                    <Col xs={22} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                        <Form.Item name="communication_email"
                                                                            rules={[
                                                                                {
                                                                                type: 'email',
                                                                                message: 'The input is not valid Email Address!',
                                                                                },
                                                                                {
                                                                                required: true,
                                                                                message: 'Please input your Communication Email Address!',
                                                                                },
                                                                            ]}>
                                                                            <Input />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row> */}
                                                                <Row>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                                                        <div style={{ display: "flex", alignContent: "center" }}>
                                                                            How many times you applied for the brand and category
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                                                        <div style={{ display: "flex", alignContent: "center" }}>
                                                                            <Form
                                                                                form={AppliedTimes}
                                                                                name="register"
                                                                                requiredMark={true}
                                                                                colon={false}
                                                                            >
                                                                                <Form.Item name="appliedTimes">
                                                                                    <Radio.Group>
                                                                                        <Radio value={"0"}>0</Radio>
                                                                                        <Radio value={"1-3"}>1-3</Radio>
                                                                                        <Radio value={"4+"}>4+</Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </Form>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ display: "flex", alignContent: "center" }}>
                                                                    How Did You Hear About Us <span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                                                </div>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <div>
                                                                        <Form.Item
                                                                            name="hearAboutus"
                                                                            rules={[{
                                                                                required: true,
                                                                                message: "How Did You Hear About is Required"
                                                                            }]}
                                                                            onChange={(value) => {
                                                                                console.log("value : ", value?.target?.value);
                                                                                if (
                                                                                    value?.target?.value === "social_media" ||
                                                                                    value?.target?.value === "you_tube" ||
                                                                                    value?.target?.value === "friends" ||
                                                                                    value?.target?.value === "google_ads"
                                                                                ) {
                                                                                    setOthers(null);
                                                                                } else {
                                                                                    setOthers("others");
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Radio.Group>
                                                                                <Space direction="vertical">
                                                                                    <Row>
                                                                                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
                                                                                            <Radio value={"social_media"}>Social Media</Radio>
                                                                                        </Col>
                                                                                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
                                                                                            <Radio value={"you_tube"} style={{ zIndex: 1 }}>Youtube</Radio>
                                                                                        </Col>
                                                                                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
                                                                                            <Radio value={"friends"} style={{ zIndex: 1 }}>Friends</Radio>
                                                                                        </Col>
                                                                                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
                                                                                            <Radio value={"google_ads"} style={{ zIndex: 1 }}>Google Ads</Radio>
                                                                                        </Col>
                                                                                        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={24}>
                                                                                            <Radio value={"others"} style={{ marginTop: "-20px" }}>
                                                                                                <div>
                                                                                                    <Form.Item
                                                                                                        name="others"
                                                                                                        rules={[{
                                                                                                            required: others === "others" ? true : false,
                                                                                                            message: "Required"
                                                                                                        }]}
                                                                                                    >
                                                                                                        <Input
                                                                                                            readOnly={others === "others" ? false : true}
                                                                                                            style={{ marginTop: "15%", padding: "0px", paddingLeft: "10px", paddingBottom: "1px" }}
                                                                                                            placeholder="others"
                                                                                                            value={othersValue}
                                                                                                            onChange={(value) => {
                                                                                                                setOthers("others");
                                                                                                                setOthersValue(value?.target?.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </Form.Item>
                                                                                                </div>
                                                                                            </Radio>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Space>
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </Col>
                                                        <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} />
                                                        <Col xs={22} sm={22} md={22} lg={9} xl={9} xxl={9}>
                                                            {/* {!customeNew && ( */}
                                                            <>
                                                                <Row align={"middle"}>
                                                                    <Col span={24}>
                                                                        <fieldset className="fieldset">
                                                                            <legend className="legend">
                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                                    <span>{"Seller Central Business Address Page"}</span>
                                                                                    <Button
                                                                                        title="Guide"
                                                                                        type="link"
                                                                                        onClick={() => {
                                                                                            setGuide(true);
                                                                                        }}
                                                                                    >
                                                                                        <QuestionCircleOutlined />
                                                                                    </Button>
                                                                                </div>
                                                                            </legend>
                                                                            <span style={{ fontSize: "12px", marginTop: "2%", marginBottom: "2%" }}><b>{"Note : "}</b>{"[Settings-> Account Info-> Business Address]"}</span>
                                                                            <div style={{ display: "flex", textAlign: "center", alignItems: "center" }}>
                                                                                <div className="imageProgress" style={{ textAlign: "justify" }}>
                                                                                    <div>
                                                                                        <Form.Item
                                                                                            name="file"
                                                                                            rules={[{
                                                                                                required: !customeNew && !ImageBuffer ? true : false,
                                                                                                message: imageError ? imageError : "Screenshot of the Seller Central Business Address Page Required"
                                                                                            }]}
                                                                                            style={{ padding: "0px", margin: "0px" }}
                                                                                        >
                                                                                            <input
                                                                                                type="file"
                                                                                                id="InputFile"
                                                                                                disabled={Uploading ? true : false}
                                                                                                accept="image/png, image/jpg, image/jpeg"
                                                                                                style={{ border: "none", boxShadow: "none", width: "100%" }}
                                                                                                onChange={async (e) => {
                                                                                                    try {
                                                                                                        setImageUploadLoading(true);
                                                                                                        console.log("File : ", e?.target?.value);
                                                                                                        let extension = e?.target?.value.split('.').pop();
                                                                                                        extension = extension && extension.toLowerCase();
                                                                                                        console.log("extension : ", extension);
                                                                                                        if (extension === "jpeg") {
                                                                                                            console.log("jpeg");
                                                                                                            setImageError(undefined)
                                                                                                            setPercent(10);
                                                                                                            if (!Uploading) {
                                                                                                                setPercent(20);
                                                                                                                setUploading(true);
                                                                                                                let file = e.target.files;
                                                                                                                const formData = new FormData();
                                                                                                                formData.append("file", file[0]);
                                                                                                                setPercent(40);
                                                                                                                const resp = await axios.post(`${baseURL}imageupload`, formData);
                                                                                                                setPercent(100);
                                                                                                                console.log("resp : ", resp);
                                                                                                                setImageBuffer(resp?.data?.data);
                                                                                                                console.log("resp?.data?.message : ", resp?.data?.message);
                                                                                                                setUploading(false);
                                                                                                                setImageUploadLoading(false);
                                                                                                            }
                                                                                                        } else {
                                                                                                            if (extension === "png" || extension === "jpg") {
                                                                                                                const fi = await document.getElementById('InputFile');
                                                                                                                console.log("fi : ", fi);
                                                                                                                if (fi.files.length > 0) {
                                                                                                                    let i;
                                                                                                                    for (i = 0; i <= fi.files.length - 1; i++) {
                                                                                                                        const fsize = fi.files.item(i).size;
                                                                                                                        const file = Math.round((fsize / 1024));
                                                                                                                        if (file >= 15000) {
                                                                                                                            alert("File too Big, please select a file less than 4mb");
                                                                                                                        } else {
                                                                                                                            setImageError(undefined)
                                                                                                                            setPercent(10);
                                                                                                                            if (!Uploading) {
                                                                                                                                setPercent(20);
                                                                                                                                setUploading(true);
                                                                                                                                let file = e.target.files;
                                                                                                                                const formData = new FormData();
                                                                                                                                formData.append("file", file[0]);
                                                                                                                                setPercent(40);
                                                                                                                                const resp = await axios.post(`${baseURL}imageupload`, formData);
                                                                                                                                setPercent(100);
                                                                                                                                console.log("resp : ", resp);
                                                                                                                                setImageBuffer(resp?.data?.data);
                                                                                                                                console.log("resp?.data?.message : ", resp?.data?.message);
                                                                                                                                setUploading(false);
                                                                                                                                setImageUploadLoading(false);
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            } else {
                                                                                                                setImageUploadLoading(false);
                                                                                                                setImageError("Accept Only image/png, image/jpg, image/jpeg")
                                                                                                            }
                                                                                                        }
                                                                                                    } catch (error) {
                                                                                                        console.log("jpeg", error);
                                                                                                    }
                                                                                                }}
                                                                                                onDrag={(val) => {
                                                                                                    console.log("val : ", val);
                                                                                                }}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Progress percent={percent} steps={5} />
                                                                                    </div>
                                                                                </div>
                                                                                {ImageBuffer?.Location && (
                                                                                    <div style={{ textAlign: "center", width: "40%" }}>
                                                                                        <Button type="primary" onClick={() => setVisible(true)}>
                                                                                            Preview
                                                                                        </Button>
                                                                                        <Image
                                                                                            width={200}
                                                                                            style={{
                                                                                                display: 'none',
                                                                                            }}
                                                                                            src={`${cloudFront}${ImageBuffer.Key && ImageBuffer.Key.replace("tfg-form", "")}`}
                                                                                            preview={{
                                                                                                visible,
                                                                                                scaleStep: 1,
                                                                                                src: `${cloudFront}${ImageBuffer.Key && ImageBuffer.Key.replace("tfg-form", "")}`,
                                                                                                onVisibleChange: (value) => {
                                                                                                    setVisible(value);
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </fieldset>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <fieldset className="fieldset">
                                                                            <legend className="legend">

                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                                    <span>{"Seller Central Legal Entity Page"}</span>
                                                                                    <Button
                                                                                        title="Guide"
                                                                                        type="link"
                                                                                        onClick={() => {
                                                                                            setGuide(true);
                                                                                        }}
                                                                                    >
                                                                                        <QuestionCircleOutlined />
                                                                                    </Button>
                                                                                </div>
                                                                            </legend>
                                                                            <span style={{ fontSize: "12px", marginTop: "2%", marginBottom: "2%" }}><b>{"Note : "}</b>{"[Settings-> Account Info -> Legal Entity]"}</span>
                                                                            <div style={{ display: "flex", textAlign: "center", alignItems: "center" }}>
                                                                                <div className="imageProgress" style={{ textAlign: "justify" }}>
                                                                                    <div>
                                                                                        <Form.Item
                                                                                            name="file2"
                                                                                            rules={[{
                                                                                                required: !customeNew && !ImageBuffer2 ? true : false,
                                                                                                message: imageError1 ? imageError1 : "Screenshot of the Seller Central Legal Entity Page Required",
                                                                                            }]}
                                                                                            style={{ padding: "0px", margin: "0px" }}
                                                                                        >
                                                                                            <input
                                                                                                type="file"
                                                                                                id="InputFile1"
                                                                                                disabled={Uploading ? true : false}
                                                                                                style={{ border: "none", boxShadow: "none", width: "100%" }}
                                                                                                onChange={async (e) => {
                                                                                                    setImageUploadLoading(true);
                                                                                                    console.log("File : ", e?.target?.value);
                                                                                                    // const extension = e?.target?.value.split('.').pop();
                                                                                                    // console.log("extension : ", extension);
                                                                                                    let extension = e?.target?.value.split('.').pop();
                                                                                                    extension = extension && extension.toLowerCase();
                                                                                                    if (extension === "jpeg") {
                                                                                                        setImageError1(undefined)
                                                                                                        setPercent2(10);
                                                                                                        if (!Uploading) {
                                                                                                            setPercent2(20);
                                                                                                            setUploading(true);
                                                                                                            let file = e.target.files;
                                                                                                            const formData = new FormData();
                                                                                                            formData.append("file", file[0]);
                                                                                                            setPercent2(40);
                                                                                                            const resp = await axios.post(`${baseURL}imageupload`, formData);
                                                                                                            setPercent2(100);
                                                                                                            console.log("resp : ", resp);
                                                                                                            setImageBuffer2(resp?.data?.data);
                                                                                                            console.log("resp?.data?.message : ", resp?.data?.message);
                                                                                                            setUploading(false);
                                                                                                            setImageUploadLoading(false);
                                                                                                        }
                                                                                                    } else {
                                                                                                        if (extension === "png" || extension === "jpg") {
                                                                                                            const fi = await document.getElementById('InputFile1');
                                                                                                            if (fi.files.length > 0) {
                                                                                                                let i;
                                                                                                                for (i = 0; i <= fi.files.length - 1; i++) {
                                                                                                                    const fsize = fi.files.item(i).size;
                                                                                                                    const file = Math.round((fsize / 1024));
                                                                                                                    if (file >= 15000) {
                                                                                                                        alert("File too Big, please select a file less than 4mb");
                                                                                                                    } else {
                                                                                                                        setImageError1(undefined)
                                                                                                                        setPercent2(10);
                                                                                                                        if (!Uploading) {
                                                                                                                            setPercent2(20);
                                                                                                                            setUploading(true);
                                                                                                                            let file = e.target.files;
                                                                                                                            const formData = new FormData();
                                                                                                                            formData.append("file", file[0]);
                                                                                                                            setPercent2(40);
                                                                                                                            const resp = await axios.post(`${baseURL}imageupload`, formData);
                                                                                                                            setPercent2(100);
                                                                                                                            console.log("resp : ", resp);
                                                                                                                            setImageBuffer2(resp?.data?.data);
                                                                                                                            console.log("resp?.data?.message : ", resp?.data?.message);
                                                                                                                            setUploading(false);
                                                                                                                            setImageUploadLoading(false);
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        } else {
                                                                                                            setImageError1("Accept Only image/png, image/jpg, image/jpeg");
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                                accept=".jpg, .jpeg, .png"
                                                                                                onDrag={(val) => {
                                                                                                    console.log("val : ", val);
                                                                                                }}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Progress percent={percent2} steps={5} />
                                                                                    </div>
                                                                                </div>
                                                                                {ImageBuffer2?.Location && (
                                                                                    <div style={{ textAlign: "center", width: "40%" }}>
                                                                                        <Button type="primary" onClick={() => setVisible1(true)}>
                                                                                            Preview
                                                                                        </Button>
                                                                                        <Image
                                                                                            width={200}
                                                                                            style={{
                                                                                                display: 'none',
                                                                                            }}
                                                                                            src={`${cloudFront}${ImageBuffer2.Key && ImageBuffer2.Key.replace("tfg-form", "")}`}
                                                                                            preview={{
                                                                                                visible: visible1,
                                                                                                scaleStep: 1,
                                                                                                src: `${cloudFront}${ImageBuffer2.Key && ImageBuffer2.Key.replace("tfg-form", "")}`,
                                                                                                onVisibleChange: (value) => {
                                                                                                    setVisible1(value);
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                        {/* <Image
                                                                                                width={200}
                                                                                                src={`${cloudFront}${ImageBuffer2.Key &&
                                                                                                    ImageBuffer2.Key.replace("tfg-form", "")
                                                                                                    }`}
                                                                                                placeholder={
                                                                                                    <Image
                                                                                                        preview={false}
                                                                                                        src={`${cloudFront}${ImageBuffer2.Key &&
                                                                                                            ImageBuffer2.Key.replace("tfg-form", "")
                                                                                                            }`}
                                                                                                        width={200}
                                                                                                    />
                                                                                                }
                                                                                            /> */}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </fieldset>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            {/* )} */}
                                                            {/* <fieldset className="fieldset">
                                                                    <legend className="legend">
        
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <span>{"Seller Central Legal Entity Page"}</span>
                                                                            <Button
                                                                                title="Guide"
                                                                                type="link"
                                                                                onClick={() => {
                                                                                    setGuide(true);
                                                                                }}
                                                                            >
                                                                                <QuestionCircleOutlined />
                                                                            </Button>
                                                                        </div>
                                                                    </legend>
                                                                    <div style={{ display: "flex", textAlign: "center", alignItems: "center" }}>
                                                                        <div className="imageProgress" style={{ textAlign: "justify" }}>
                                                                            <div>
                                                                                <Form.Item
                                                                                    name="file2"
                                                                                    rules={[{
                                                                                        required: !ImageBuffer2,
                                                                                        message: "Seller Central Business Address Page from seller central Required"
                                                                                    }]}
                                                                                    style={{ padding: "0px", margin: "0px" }}
                                                                                >
                                                                                    <input
                                                                                        type="file"
                                                                                        disabled={Uploading ? true : false}
                                                                                        style={{ border: "none", boxShadow: "none" }}
                                                                                        onChange={async (e) => {
                                                                                            setPercent2(10);
                                                                                            if (!Uploading) {
                                                                                                setPercent2(20);
                                                                                                setUploading(true)
                                                                                                let file = e.target.files;
                                                                                                const formData = new FormData();
                                                                                                formData.append("file", file[0]);
                                                                                                setPercent2(40);
                                                                                                const resp = await axios.post(`${baseURL}imageupload`, formData);
                                                                                                setPercent2(100);
                                                                                                console.log("resp : ", resp);
                                                                                                setImageBuffer2(resp?.data?.data);
                                                                                                console.log("resp?.data?.message : ", resp?.data?.message);
                                                                                                setUploading(false)
                                                                                            }
                                                                                        }}
                                                                                        accept=".jpg, .jpeg, .png"
                                                                                        onDrag={(val) => {
                                                                                            console.log("val : ", val);
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div>
                                                                                <Progress percent={percent2} steps={5} />
                                                                            </div>
                                                                        </div>
                                                                        {ImageBuffer2?.Location && (
                                                                            <div style={{ textAlign: "center" }}>
                                                                                <Button type="primary" onClick={() => setVisible1(true)}>
                                                                                    show image preview
                                                                                </Button>
                                                                                <Image
                                                                                    width={200}
                                                                                    style={{
                                                                                        display: 'none',
                                                                                    }}
                                                                                    src={`${cloudFront}${ImageBuffer2.Key && ImageBuffer2.Key.replace("tfg-form", "")}`}
                                                                                    preview={{
                                                                                        visible: visible1,
                                                                                        scaleStep: 1,
                                                                                        src: `${cloudFront}${ImageBuffer2.Key && ImageBuffer2.Key.replace("tfg-form", "")}`,
                                                                                        onVisibleChange: (value) => {
                                                                                            setVisible1(value);
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </fieldset> */}
                                                        </Col>
                                                        <Col xs={0} sm={0} md={0} lg={2} xl={2} xxl={2} />
                                                    </Row>
                                                </>
                                            )}
                                            <br />
                                            {current < 2 && (
                                                <Row align={"middle"}>
                                                    {/* <Col span={4} />
                                                <Col span={8} > */}
                                                    <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    <Col xs={22} sm={11} md={11} lg={11} xl={8} xxl={8} >
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Form.Item>
                                                                <button
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    disabled={current > 0 ? false : true}
                                                                    onClick={() => {
                                                                        setPreviousButton(true);
                                                                        console.log({ formData });
                                                                        console.log({ ungating });
                                                                        let Data = form.getFieldValue();
                                                                        setPreviosData(Data);
                                                                        console.log("Data Prev : ", Data);
                                                                        const formValue = form.getFieldsValue();
                                                                        console.log({ formValue });
                                                                        form.setFieldsValue(
                                                                            {
                                                                                market_place: formData?.market_place,
                                                                                categories: formData?.categories,
                                                                                account_name: formData?.account_name,
                                                                                seller_account_email: formData?.seller_account_email,
                                                                                payment_email: formData?.payment_email,
                                                                                communication_email: formData?.communication_email,
                                                                                customer_comment: formData?.customer_comment,
                                                                                hearAboutus: formData?.hearAboutus,
                                                                                facebook_profile: formData?.facebook_profile,
                                                                                others: formData?.others,
                                                                                brandUngating: formData?.brandUngating,
                                                                                poa: formData?.poa,
                                                                                file: null,
                                                                                file2: null,
                                                                            }
                                                                        );
                                                                        setCurrent(current - 1);
                                                                        setPreviousButton(false);
                                                                    }}
                                                                    style={{
                                                                        boxShadow: "none",
                                                                        color: current > 0 ? "#fff" : "#000",
                                                                        cursor: current > 0 ? "pointer" : "not-allowed",
                                                                        backgroundColor: current > 0 ? "#1677ff" : "#d9d9d9",
                                                                        padding: "4px 15px",
                                                                        border: "2px",
                                                                        height: "32px",
                                                                        borderRadius: "6px",
                                                                        width: "100px",
                                                                    }}
                                                                >
                                                                    Previous
                                                                </button>
                                                            </Form.Item> &nbsp;&nbsp;
                                                            <Form.Item>
                                                                <button
                                                                    style={{
                                                                        boxShadow: "none",
                                                                        color: "#fff",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "#1677ff",
                                                                        padding: "4px 15px",
                                                                        border: "2px",
                                                                        height: "32px",
                                                                        width: "100px",
                                                                        borderRadius: "6px",
                                                                    }}
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    onClick={() => {
                                                                        let Data = form.getFieldValue();
                                                                        let AppliedTimesForm = AppliedTimes.getFieldValue();
                                                                        console.log("Data : ", Data?.facebook_profile);
                                                                        console.log("previos Data : ", previosData?.facebook_profile);
                                                                        const FormData = {
                                                                            account_name: current === 1 && previosData ? Data?.account_name : previosData?.account_name ?? Data?.account_name,
                                                                            appliedTimes: AppliedTimesForm?.appliedTimes ?? null,
                                                                            brandUngating: Data?.brandUngating,
                                                                            categories: Data?.categories,
                                                                            communication_email: current === 1 && previosData ? Data?.communication_email : previosData?.communication_email ?? Data?.communication_email,
                                                                            customer_comment: current === 1 && previosData ? Data?.customer_comment : previosData?.customer_comment ?? Data?.customer_comment,
                                                                            facebook_profile: current === 1 && previosData ? Data?.facebook_profile : previosData?.facebook_profile ?? Data?.facebook_profile,
                                                                            file: null,
                                                                            file2: null,
                                                                            hearAboutus: current === 1 && previosData ? Data?.hearAboutus : previosData?.hearAboutus ?? Data?.hearAboutus,
                                                                            market_place: Data?.market_place,
                                                                            others: current === 1 && previosData ? Data?.others : previosData?.others ?? Data?.others,
                                                                            payment_email: current === 1 && previosData ? Data?.payment_email : previosData?.payment_email ?? Data?.payment_email,
                                                                            poa: Data?.poa,
                                                                            seller_account_email: current === 1 && previosData ? Data?.seller_account_email : previosData?.seller_account_email ?? Data?.seller_account_email,
                                                                        }
                                                                        form.setFieldsValue({
                                                                            // account_name: Data?.account_name,
                                                                            account_name: current === 1 && previosData ? Data?.account_name : previosData?.account_name ?? Data?.account_name,
                                                                            appliedTimes: AppliedTimesForm?.appliedTimes ?? null,
                                                                            brandUngating: Data?.brandUngating,
                                                                            categories: Data?.categories,
                                                                            communication_email: current === 1 && previosData ? Data?.communication_email : previosData?.communication_email ?? Data?.communication_email,
                                                                            customer_comment: current === 1 && previosData ? Data?.customer_comment : previosData?.customer_comment ?? Data?.customer_comment,
                                                                            facebook_profile: current === 1 && previosData ? Data?.facebook_profile : previosData?.facebook_profile ?? Data?.facebook_profile,
                                                                            file: null,
                                                                            file2: null,
                                                                            hearAboutus: current === 1 && previosData ? Data?.hearAboutus : previosData?.hearAboutus ?? Data?.hearAboutus,
                                                                            market_place: Data?.market_place,
                                                                            others: current === 1 && previosData ? Data?.others : previosData?.others ?? Data?.others,
                                                                            payment_email: current === 1 && previosData ? Data?.payment_email : previosData?.payment_email ?? Data?.payment_email,
                                                                            poa: Data?.poa,
                                                                            seller_account_email: current === 1 && previosData ? Data?.seller_account_email : previosData?.seller_account_email ?? Data?.seller_account_email,
                                                                        });
                                                                        setFormData(FormData);
                                                                    }}
                                                                >
                                                                    Next
                                                                </button>
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                    <Col xs={0} sm={11} md={11} lg={6} xl={9} xxl={9}>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Form>
                                        {current === 2 && (
                                            <div>
                                                <Row gutter={[16, 16]}>
                                                    <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                        <div style={{ fontWeight: "bold" }}>{"Seller Account Display Name"}</div>
                                                    </Col>
                                                    <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                        <span>{formData?.account_name}</span>
                                                    </Col>
                                                    <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />

                                                    {/* <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                        <div style={{fontWeight:"bold"}}>{"How Many times you Applied"}</div>
                                                    </Col>
                                                    <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                        <span>{AppliedTimes.getFieldValue()?.appliedTimes}</span>
                                                    </Col>
                                                    <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} /> */}


                                                    <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                        <div style={{ fontWeight: "bold" }}>{"Seller Account Email Address"}</div>
                                                    </Col>
                                                    <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                        <span>{formData?.seller_account_email}</span>
                                                    </Col>
                                                    <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />

                                                    <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                        <div style={{ fontWeight: "bold" }}>{"Amazon Marketplace"}</div>
                                                    </Col>
                                                    <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} style={{ textTransform: "uppercase" }}>
                                                        <span style={{ textTransform: "capitalize" }}>{formData?.market_place === "multiple_market_places" ? MultipleMarketplace : formData?.market_place}</span>
                                                    </Col>
                                                    <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />

                                                    <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                        <div style={{ fontWeight: "bold" }}>{"Communication Email Address"}</div>
                                                    </Col>
                                                    <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                        <span>{formData?.communication_email}</span>
                                                    </Col>
                                                    <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />

                                                    <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                        <div style={{ fontWeight: "bold" }}>{"Payment Email Address"}</div>
                                                    </Col>
                                                    <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                        <span>{formData?.payment_email}</span>
                                                    </Col>
                                                    <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    {formData?.customer_comment && (
                                                        <>
                                                            <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                                <div style={{ fontWeight: "bold" }}>{"Comment"}</div>
                                                            </Col>
                                                            <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <span>{formData?.customer_comment}</span>
                                                            </Col>
                                                            <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                        </>
                                                    )}
                                                    {formData?.facebook_profile && (
                                                        <>
                                                            <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                                <div style={{ fontWeight: "bold" }}>{"Facebook Profile"}</div>
                                                            </Col>
                                                            <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <span>{formData?.facebook_profile}</span>
                                                            </Col>
                                                            <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                        </>
                                                    )}

                                                    <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                        <div style={{ fontWeight: "bold" }}>{"How did you hear about us"}</div>
                                                    </Col>
                                                    <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                    <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                        <span style={{ textTransform: "capitalize" }}>{others === "others" ? othersValue : formData?.hearAboutus === "you_tube" ? formData?.hearAboutus.replace(/_/g, "") : formData?.hearAboutus.replace(/_/g, " ")}</span>
                                                    </Col>
                                                    <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />

                                                    {ImageBuffer?.Key && (
                                                        <>
                                                            <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                                <div style={{ fontWeight: "bold" }}>{"Business Address Screenshot"}</div>
                                                            </Col>
                                                            <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <div>
                                                                    <button
                                                                        style={{
                                                                            boxShadow: "none",
                                                                            color: "#fff",
                                                                            cursor: "pointer",
                                                                            backgroundColor: "#1677ff",
                                                                            padding: "2px 10px",
                                                                            border: "2px",
                                                                            height: "25px",
                                                                            width: "100px",
                                                                            borderRadius: "6px",
                                                                        }}
                                                                        type="primary"
                                                                        onClick={() => setVisible(true)}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                    <Image
                                                                        width={200}
                                                                        style={{
                                                                            display: 'none',
                                                                        }}
                                                                        src={`${cloudFront}${ImageBuffer.Key && ImageBuffer.Key.replace("tfg-form", "")}`}
                                                                        preview={{
                                                                            visible,
                                                                            scaleStep: 1,
                                                                            src: `${cloudFront}${ImageBuffer.Key && ImageBuffer.Key.replace("tfg-form", "")}`,
                                                                            onVisibleChange: (value) => {
                                                                                setVisible(value);
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                        </>
                                                    )}
                                                    {ImageBuffer2?.Key && (
                                                        <>
                                                            <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                                <div style={{ fontWeight: "bold" }}>{"Legal Entity Screenshot"}</div>
                                                            </Col>
                                                            <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <div>
                                                                    <button
                                                                        style={{
                                                                            boxShadow: "none",
                                                                            color: "#fff",
                                                                            cursor: "pointer",
                                                                            backgroundColor: "#1677ff",
                                                                            padding: "2px 10px",
                                                                            border: "2px",
                                                                            height: "25px",
                                                                            width: "100px",
                                                                            borderRadius: "6px",
                                                                        }}
                                                                        type="primary"
                                                                        onClick={() => setVisible1(true)}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                    <Image
                                                                        width={200}
                                                                        style={{
                                                                            display: 'none',
                                                                        }}
                                                                        src={`${cloudFront}${ImageBuffer2.Key && ImageBuffer2.Key.replace("tfg-form", "")}`}
                                                                        preview={{
                                                                            visible: visible1,
                                                                            scaleStep: 1,
                                                                            src: `${cloudFront}${ImageBuffer2.Key && ImageBuffer2.Key.replace("tfg-form", "")}`,
                                                                            onVisibleChange: (value) => {
                                                                                setVisible1(value);
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                        </>
                                                    )}

                                                    {ungating?.includes("brand_ungating") && (
                                                        <>
                                                            <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                                <div style={{ fontWeight: "bold" }}>{"Brand Ungating"}</div>
                                                            </Col>
                                                            <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                                {formData?.brandUngating.map((item) => (
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <div>
                                                                                <div>
                                                                                    <span>{`${item?.brand}${item?.asin ? " - " + item?.asin : ""}`}</span>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                ))}
                                                            </Col>
                                                            <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                        </>
                                                    )}

                                                    {ungating?.includes("misc_pack") && (
                                                        <>
                                                            <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                                <div style={{ fontWeight: "bold" }}>{"POA"}</div>
                                                            </Col>
                                                            <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <span style={{ whiteSpace: "pre-wrap" }}>{miscPack}</span>
                                                            </Col>
                                                            <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                        </>
                                                    )}
                                                    {!SelectCategory && (
                                                        <>
                                                            <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                                <div style={{ fontWeight: "bold" }}>{"Category  "}</div>
                                                            </Col>
                                                            <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                            <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <div>
                                                                    {ungating?.includes("audioVisual") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Audio Visual"}{audioVisual && " - " + audioVisual}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("automotive") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Automotive"}{automotive && " - " + automotive}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("babyDiapers") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Baby Diapers"}{babyDiapers && " - " + babyDiapers}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}




                                                                    {ungating?.includes("babyStrollerAndCarrier") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Baby Stroller and Carrier"}{babyStrollerAndCarrier && " - " + babyStrollerAndCarrier}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("babyTopical") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Baby Topical"}{babyTopical && " - " + babyTopical}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("candleAndAromatherapy") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Candle and Aromatherapy"}{candleAndAromatherapy && " - " + candleAndAromatherapy}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("chargersAndBatteries") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Chargers and Batteries"}{chargersAndBatteries && " - " + chargersAndBatteries}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("DVDAndBluRayMedias") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"DVD and Bluray Media"}{DVDAndBluRayMedias && " - " + DVDAndBluRayMedias}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("gardeningAndWatering") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Gardening and Watering"}{gardeningAndWatering && " - " + gardeningAndWatering}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("hairCareAndBeautyAppliances") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"HairCare and Beauty Appliances"}{hairCareAndBeautyAppliances && " - " + hairCareAndBeautyAppliances}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("homeElectronics") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Home Electronics"}{homeElectronics && " - " + homeElectronics}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("kidsBabyFurniture") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Kids Baby Furniture"}{kidsBabyFurniture && " - " + kidsBabyFurniture}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("lighting") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Lighting"}{lighting && " - " + lighting}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("music") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Music"}{music && " - " + music}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("otcMedications") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"OTC Medications"}{otcMedications && " - " + otcMedications}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("personalSafetyAndHousehold") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Personal Safety and Household"}{personalSafetyAndHousehold && " - " + personalSafetyAndHousehold}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("petFood") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Pet Food"}{petFood && " - " + petFood}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("PopularMusic") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Popular Music"}{PopularMusic && " - " + PopularMusic}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("PowerTools") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Power Tools"}{PowerTools && " - " + PowerTools}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("Topical") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Topical (AKA Beauty)"}{Topical && " - " + Topical}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("Watches") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Watches"}{Watches && " - " + Watches}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("WirelessAccessories") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Wireless Accessories"}{WirelessAccessories && " - " + WirelessAccessories}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("popularDVD") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Popular DVDs"}{popularDVD && " - " + popularDVD}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("dietarySupplements") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Dietary Supplements"}{dietarySupplements && " - " + dietarySupplements}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("babyFeeding") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Baby Feeding"}{babyFeeding && " - " + babyFeeding}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("grocery_gourmet_food") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Grocery and Gourmet Food"}{groceryFoods && " - " + groceryFoods}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("toys") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Toys"}{usaToys && " - " + usaToys}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("pet_supplies") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Pet Supplies"}{petSuplies && " - " + petSuplies}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("medical_supplies") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Medical Supplies"}{medicaSuplies && " - " + medicaSuplies}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("pet_care") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Pet Care"}{petCare && " - " + petCare}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("femine_hygine") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Feminine Hygiene"}{femineHygine && " - " + femineHygine}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                    {ungating?.includes("baby_activity_gear") && (
                                                                        <>
                                                                            <Row>
                                                                                <Col span={24}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>{"Baby Activity Gear"}{babyActivityGear && " - " + babyActivityGear}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col span={12}>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                        </>
                                                    )}
                                                </Row>

                                                <br />
                                            </div>
                                        )}
                                    </div>
                                    {current == 2 && (
                                        <div style={{ textAlign: "center" }}>
                                            <Row align={"middle"}>
                                                <Col xs={2} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                                <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5} >
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <button
                                                            type="primary"
                                                            htmlType="submit"
                                                            disabled={current > 0 ? false : true}
                                                            style={{
                                                                boxShadow: "none",
                                                                color: current > 0 ? "#fff" : "#000",
                                                                cursor: current > 0 ? "pointer" : "not-allowed",
                                                                backgroundColor: current > 0 ? "#1677ff" : "#d9d9d9",
                                                                padding: "4px 15px",
                                                                border: "2px",
                                                                height: "32px",
                                                                borderRadius: "6px",
                                                                width: "100px",
                                                            }}
                                                            onClick={() => {
                                                                console.log({ formData });
                                                                console.log({ ungating });
                                                                let DataPrevious = form.getFieldValue();
                                                                console.log("Data Previous : ", DataPrevious);
                                                                let AppliedTimesForm = AppliedTimes.getFieldValue();

                                                                form.setFieldsValue(
                                                                    {
                                                                        market_place: formData?.market_place,
                                                                        categories: formData?.categories,
                                                                        account_name: formData?.account_name,
                                                                        seller_account_email: formData?.seller_account_email,
                                                                        payment_email: formData?.payment_email,
                                                                        communication_email: formData?.communication_email,
                                                                        customer_comment: formData?.customer_comment,
                                                                        hearAboutus: formData?.hearAboutus,
                                                                        facebook_profile: formData?.facebook_profile,
                                                                        others: formData?.others,
                                                                        appliedTimes: AppliedTimesForm?.appliedTimes,
                                                                        file: null,
                                                                        file2: null,
                                                                    }
                                                                );
                                                                const formValuePrev = { ...formData };
                                                                console.log({ formValuePrev });
                                                                setFormData(formValuePrev);
                                                                setCurrent(current - 1);
                                                                setPreviousButton(false);
                                                            }}
                                                        >
                                                            Previous
                                                        </button> &nbsp;&nbsp;
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            loading={submit}
                                                            disabled={submit}
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            onClick={async () => {
                                                                let DataSubmit = form.getFieldValue();
                                                                console.log("Data Submit : ", DataSubmit);

                                                                setSubmit(true);
                                                                console.log("formData : ", formData);
                                                                console.log("ungating : ", ungating);
                                                                let Data = {
                                                                    sellerAccount: formData?.account_name,
                                                                    AmazonMarketplace: formData?.market_place === "multiple_market_places" ? MultipleMarketplace : formData?.market_place,
                                                                    CommunicationEmail: formData?.communication_email?.toLowerCase(),
                                                                    customer_comment: formData?.customer_comment,
                                                                    facebookProfile: formData?.facebook_profile,
                                                                    hearAboutus: formData?.hearAboutus,
                                                                    paymentMail: formData?.payment_email?.toLowerCase(),
                                                                    accountMail: formData?.seller_account_email?.toLowerCase(),
                                                                    brandUngating: ungating?.includes("brand_ungating") ? formData?.brandUngating : [],
                                                                    poa: formData?.poa,
                                                                    image: ImageBuffer,
                                                                    image2: ImageBuffer2,
                                                                    others: othersValue ?? null,
                                                                    appliedTimes: AppliedTimes.getFieldValue()?.appliedTimes,
                                                                    ungating: []
                                                                };
                                                                if (ungating?.includes("audioVisual")) {
                                                                    Data.ungating.push({
                                                                        category: "audioVisual",
                                                                        asin: audioVisual
                                                                    })
                                                                };
                                                                if (ungating?.includes("automotive")) {
                                                                    Data.ungating.push({
                                                                        category: "automotive",
                                                                        asin: automotive
                                                                    })
                                                                };
                                                                if (ungating?.includes("babyDiapers")) {
                                                                    Data.ungating.push({
                                                                        category: "babyDiapers",
                                                                        asin: babyDiapers
                                                                    })
                                                                };
                                                                if (ungating?.includes("babyStrollerAndCarrier")) {
                                                                    Data.ungating.push({
                                                                        category: "babyStrollerAndCarrier",
                                                                        asin: babyStrollerAndCarrier
                                                                    })
                                                                };
                                                                if (ungating?.includes("babyTopical")) {
                                                                    Data.ungating.push({
                                                                        category: "babyTopical",
                                                                        asin: babyTopical
                                                                    })
                                                                };
                                                                if (ungating?.includes("candleAndAromatherapy")) {
                                                                    Data.ungating.push({
                                                                        category: "candleAndAromatherapy",
                                                                        asin: candleAndAromatherapy
                                                                    })
                                                                };
                                                                if (ungating?.includes("chargersAndBatteries")) {
                                                                    Data.ungating.push({
                                                                        category: "chargersAndBatteries",
                                                                        asin: chargersAndBatteries
                                                                    })
                                                                };
                                                                if (ungating?.includes("DVDAndBluRayMedias")) {
                                                                    Data.ungating.push({
                                                                        category: "DVDAndBluRayMedias",
                                                                        asin: DVDAndBluRayMedias
                                                                    })
                                                                };
                                                                if (ungating?.includes("gardeningAndWatering")) {
                                                                    Data.ungating.push({
                                                                        category: "gardeningAndWatering",
                                                                        asin: gardeningAndWatering
                                                                    })
                                                                };
                                                                if (ungating?.includes("hairCareAndBeautyAppliances")) {
                                                                    Data.ungating.push({
                                                                        category: "hairCareAndBeautyAppliances",
                                                                        asin: hairCareAndBeautyAppliances
                                                                    })
                                                                };
                                                                if (ungating?.includes("homeElectronics")) {
                                                                    Data.ungating.push({
                                                                        category: "homeElectronics",
                                                                        asin: homeElectronics
                                                                    })
                                                                };
                                                                if (ungating?.includes("kidsBabyFurniture")) {
                                                                    Data.ungating.push({
                                                                        category: "kidsBabyFurniture",
                                                                        asin: kidsBabyFurniture
                                                                    })
                                                                };
                                                                if (ungating?.includes("lighting")) {
                                                                    Data.ungating.push({
                                                                        category: "lighting",
                                                                        asin: lighting
                                                                    })
                                                                };
                                                                if (ungating?.includes("music")) {
                                                                    Data.ungating.push({
                                                                        category: "music",
                                                                        asin: music
                                                                    })
                                                                };
                                                                if (ungating?.includes("otcMedications")) {
                                                                    Data.ungating.push({
                                                                        category: "otcMedications",
                                                                        asin: otcMedications
                                                                    })
                                                                };
                                                                if (ungating?.includes("personalSafetyAndHousehold")) {
                                                                    Data.ungating.push({
                                                                        category: "personalSafetyAndHousehold",
                                                                        asin: personalSafetyAndHousehold
                                                                    })
                                                                };
                                                                if (ungating?.includes("petFood")) {
                                                                    Data.ungating.push({
                                                                        category: "petFood",
                                                                        asin: petFood
                                                                    })
                                                                };
                                                                if (ungating?.includes("PopularMusic")) {
                                                                    Data.ungating.push({
                                                                        category: "PopularMusic",
                                                                        asin: PopularMusic
                                                                    })
                                                                };
                                                                if (ungating?.includes("PowerTools")) {
                                                                    Data.ungating.push({
                                                                        category: "PowerTools",
                                                                        asin: PowerTools
                                                                    })
                                                                };
                                                                if (ungating?.includes("Watches")) {
                                                                    Data.ungating.push({
                                                                        category: "Watches",
                                                                        asin: Watches
                                                                    })
                                                                };
                                                                if (ungating?.includes("WirelessAccessories")) {
                                                                    Data.ungating.push({
                                                                        category: "WirelessAccessories",
                                                                        asin: WirelessAccessories
                                                                    })
                                                                };
                                                                if (ungating?.includes("popularDVD")) {
                                                                    Data.ungating.push({
                                                                        category: "popularDVD",
                                                                        asin: popularDVD
                                                                    })
                                                                };
                                                                if (ungating?.includes("dietarySupplements")) {
                                                                    Data.ungating.push({
                                                                        category: "dietarySupplements",
                                                                        asin: dietarySupplements
                                                                    })
                                                                };
                                                                if (ungating?.includes("babyFeeding")) {
                                                                    Data.ungating.push({
                                                                        category: "babyFeeding",
                                                                        asin: babyFeeding
                                                                    })
                                                                };
                                                                if (ungating?.includes("grocery_gourmet_food")) {
                                                                    Data.ungating.push({
                                                                        category: "grocery_gourmet_food",
                                                                        asin: groceryFoods
                                                                    })
                                                                };
                                                                if (ungating?.includes("toys")) {
                                                                    Data.ungating.push({
                                                                        category: "toys",
                                                                        asin: usaToys
                                                                    })
                                                                };
                                                                if (ungating?.includes("pet_supplies")) {
                                                                    Data.ungating.push({
                                                                        category: "pet_supplies",
                                                                        asin: petSuplies
                                                                    })
                                                                };
                                                                if (ungating?.includes("medical_supplies")) {
                                                                    Data.ungating.push({
                                                                        category: "medical_supplies",
                                                                        asin: medicaSuplies
                                                                    })
                                                                };
                                                                if (ungating?.includes("pet_care")) {
                                                                    Data.ungating.push({
                                                                        category: "pet_care",
                                                                        asin: petCare
                                                                    })
                                                                };
                                                                if (ungating?.includes("femine_hygine")) {
                                                                    Data.ungating.push({
                                                                        category: "femine_hygine",
                                                                        asin: femineHygine
                                                                    })
                                                                };
                                                                if (ungating?.includes("Topical")) {
                                                                    Data.ungating.push({
                                                                        category: "Topical",
                                                                        asin: Topical
                                                                    })
                                                                };
                                                                if (ungating?.includes("baby_activity_gear")) {
                                                                    Data.ungating.push({
                                                                        category: "baby_activity_gear",
                                                                        asin: babyActivityGear
                                                                    })
                                                                };
                                                                let SaveForm;
                                                                console.log("Data : ", Data);
                                                                if (formId) {
                                                                    SaveForm = await serviceCaller.UpdateForm(formId, Data);
                                                                } else {
                                                                    SaveForm = await serviceCaller.postForm(Data);
                                                                }
                                                                if (SaveForm?.data?.message === "Done") {
                                                                    notification.success({
                                                                        message: 'Form Successfully Submitted',
                                                                        description:
                                                                            'We will contact you soon',
                                                                    });
                                                                    setSubmit(false);
                                                                    window.location.replace("/partner");
                                                                    // window.location.replace("https://chrome.google.com/webstore/detail/the-funnel-guru-amazon-as/lnlfckdcdjkbhaaihnhehinbgiaoggfa?hl=en");
                                                                }
                                                                console.log({ SaveForm });
                                                            }}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={0} md={0} lg={0} xl={0} xxl={0} />
                                                <Col xs={22} sm={10} md={10} lg={5} xl={5} xxl={5}>
                                                </Col>
                                                <Col xs={0} sm={2} md={2} lg={7} xl={7} xxl={7} />
                                            </Row>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Modal
                                        title=""
                                        open={guide1}
                                        width={"70%"}
                                        centered
                                        onCancel={() => { setGuide(false); }}
                                        footer={null}
                                    >
                                        <Guide
                                            onClose={() => {
                                                setGuide(false);
                                            }}
                                        />
                                    </Modal>
                                </div>
                            </>
                            {/* )} */}
                        </div>
                    </Spin>
                </>
            )}
            <div style={{ backgroundColor: 'rgb(217,217,217)', padding: "2%", fontSize: "16px", textAlign: "center", width: "100%", position: "fixed", bottom: "0" }}>
                Having any queries? Please contact us at <a href="mailto:thefunnelguru@gmail.com">thefunnelguru@gmail.com </a>
            </div>
        </Wrapper>
    )
}

export default CustomerForm;